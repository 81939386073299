import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Row} from "reactstrap";
import PieChart, {Connector, Export, Label, Series} from 'devextreme-react/pie-chart';
import FilterModal from "./filterModal";
import {filteredReports} from "../../services/panel/reportService";
import {notify} from "../../components/toastify";
import DataGridLoadPanel from "devextreme-react/load-panel";

const pallette = ["#b12e24", "#3e7ebc", "#0ede45", "#de7d0e"]

const Reports = (props) => {
    const navigate = useHistory();
    const position = {of: '#content', at: 'top', my: 'top'};
    const [loading, setLoading] = useState(true)
    const [chartData, setChartData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        filteredReports().then((response) => {
            if (response) {
                setLoading(false)
                setChartData(response)
            }
        })
    }, []);

    const FilteredData = (value) => {
        setLoading(true)
        const queryParams = {
            cast_id: value?.cast_id?.value,
            city_id: value?.city_id?.value,
            country_id: value?.country_id?.value,
            dp_id: value?.dp_id?.value,
            end_date: value?.end_date,
            project_id: value?.project_id?.value,
            start_date: value?.start_date,
            street_name: value?.street_name?.value,
            telecom_company_id: value?.telecom_company_id?.value,
            user_id: value?.user_id?.value,
        }
        setFilters(queryParams)
        setIsFiltered(true)
        filteredReports(queryParams).then((res) => {
            if (res) {
                setLoading(false)
                setChartData(res)
                notify()
            }
        })
    }

    const toggleVisibility = (item) => {
        item.isVisible() ? item.hide() : item.show();
    }

    const onPointClick = async (e, type) => {
        const updatedFilters = {...filters};
        updatedFilters.chart_status = e.target.data.status;
        setFilters(updatedFilters);
        navigate.push({
            pathname: "/report/details",
            search: `?type=${type}&filter=${JSON.stringify(updatedFilters)}`,
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <DataGridLoadPanel
                    visible={loading}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    shading={false}
                    height={100}
                    width={140}
                    message="Loading..."
                    position={position}
                />
                <Row className="ms-2">
                    <div id="content">
                        <span className="font-size-18 font-weight-semibold me-2">{props.t("Reports")}</span>
                        {chartData && (
                            <Button color="success" className="ms-2" outline
                                    onClick={() => setIsModalOpen(true)}>{props.t("Do Filter")}</Button>
                        )}
                        {isFiltered && (
                            <Button color="warning" className="ms-2" outline onClick={() => {
                                setLoading(true)
                                setIsFiltered(false)
                                filteredReports().then((response) => {
                                    if (response) {
                                        setLoading(false)
                                        setChartData(response)
                                    }
                                })
                            }}>{props.t("Clear")}</Button>

                        )}
                    </div>
                </Row>
                {chartData && (
                    <Row className="mt-4">
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.home} palette={pallette}
                                      title={`House (${props.t("Total")}: ${chartData.homeCount})`}
                                      onPointClick={(e) => onPointClick(e, "House")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="racketPie" dataSource={chartData.racket} palette={pallette}
                                      title={`Racket (${props.t("Total")}: ${chartData.racketCount}))`}
                                      onPointClick={(e) => onPointClick(e, "Racket")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.blasenHouse} palette={pallette}
                                      title={`Blasen House (${props.t("Total")}: ${chartData.blasenHouseCount})`}
                                      onPointClick={(e) => onPointClick(e, "Blasen")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                    resolveLabelOverlapping="shift"
                                >
                                    <Label
                                        visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>

                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.blasenDP} palette={pallette}
                                      title={`Blasen DP (${props.t("Total")}: ${chartData.blasenDPCount})`}
                                      onPointClick={(e) => onPointClick(e, "Blasen-DP")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.LassenHouse} palette={pallette}
                                      title={`Lassen House (${props.t("Total")}: ${chartData.LassenHouseCount})`}
                                      onPointClick={(e) => onPointClick(e, "Lassen")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.lassenDP} palette={pallette}
                                      title={`Lassen DP (${props.t("Total")}: ${chartData.lassenDPCount})`}
                                      onPointClick={(e) => onPointClick(e, "Lassen-DP")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.lassenCast} palette={pallette}
                                      title={`Lassen Cast (${props.t("Total")}: ${chartData.lassenCastCount})`}
                                      onPointClick={(e) => onPointClick(e, "House")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                        <Col xl={4} className="shadow-sm pointer">
                            <PieChart id="pie" dataSource={chartData.has} palette={pallette}
                                      title={`Has (${props.t("Total")}: ${chartData.hasCount})`}
                                      onPointClick={(e) => onPointClick(e, "Has")}
                                      onLegendClick={(e) => {
                                          const arg = e.target;
                                          const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
                                          toggleVisibility(item);
                                      }}>
                                <Export enabled={true}/>
                                <Series
                                    argumentField="title"
                                    valueField="count"
                                >
                                    <Label visible={true}>
                                        <Connector visible={true} width={1}/>
                                    </Label>
                                </Series>
                            </PieChart>
                        </Col>
                    </Row>
                )}

                <Row>

                </Row>
                <FilterModal show={isModalOpen} toggle={() => setIsModalOpen(false)} onSubmit={(value) => {
                    FilteredData(value)
                }}/>
            </div>
        </React.Fragment>)
}

Reports.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(Reports)
)