import React from "react"
import {Redirect} from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Panel
import Panel from "../pages/Dashboard/index"
import UserList from "../pages/userManagement/userList"
import addUser from "../pages/userManagement/addUser"
import addProject from "../pages/Projects/addProjects"
import projectList from "../pages/Projects/projectList"
import projectUserList from "../pages/Projects/projectUserList"
import projectCastList from "../pages/Projects/projectCastList"
import projectDPList from "../pages/Projects/projectDPList"
import projectHouseList from "../pages/Projects/projectHouseList/projectHouseList";
import Reports from "../pages/Reports/reportChart";
import Details from "../pages/Reports/details";
import TerminSchedule from "../pages/Termin/terminSchedule";
import Termin from "../pages/Termin/terminList/termin";
import WorkList from "../pages/Worklist/workList";
import Company from "../pages/company/companyList";
import ImageManagement from "../pages/SystemManagement/imageManagement";

// Utility
import Pages404 from "../pages/Utility/pages-404";
import ColorManagement from "../pages/SystemManagement/colorManagement";


const authProtectedRoutes = [
  { path: "/", component: Panel },

  //  profile

  //  Panel
  { path: "/users", component: UserList },
  { path: "/user/add", component: addUser },
  { path: "/project/add", component: addProject },
  { path: "/projects", component: projectList },
  { path: "/projects/user" , component: projectUserList },
  { path: "/projects/cast" , component: projectCastList },
  { path: "/projects/dp" , component: projectDPList },
  { path: "/projects/house" , component: projectHouseList },
  { path: "/reports" , component: Reports },
  { path: "/report/details" , component: Details },
  { path: "/termin/schedule" , component: TerminSchedule },
  { path: "/termins" , component: Termin },
  { path: "/company" , component: Company },
  { path: "/works" , component: WorkList },
  { path: "/system/image" , component: ImageManagement },
  { path: "/system/color" , component: ColorManagement },


  //  Main
  { path: "/", exact: true, component: () => <Redirect to="/" /> },

]
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

const errorRoutes = [
  { path: "*", component: Pages404 },
]


export { publicRoutes, authProtectedRoutes,errorRoutes }
