import {COUNTRY_URL, GET_PROJECT_LIST} from "helpers/url_helper";
import {getCookie} from "../../helpers/cookie_helper";

const { post,get,del } = require("helpers/api_helper");

export const getProjectListService = async () => {
    return await get(GET_PROJECT_LIST);
}

export const delProjectService = async (projectID) => {
    return await del(`/project/${projectID}`);
}

export const getProjectUserListService = async (project_id) => {
    return await get(`/project/${project_id}/user`);
}

export const delProjectUserListService = async (projectID,userID) => {
    return await del(`/project/${projectID}/user/${userID}`);
}

export const getProjectNotUserListService = async (project_id) => {
    return await get(`/user?not_in_project=${project_id}`);
}

export const addProjectUserListService = async (projectID,userID) => {
    const response = await post(`/project/${projectID}/user`,{user_id: userID});
    return response.data;
}

export const getCastListService = async (projectID) => {
    return await get(`/project/${projectID}/cast`);
}

export const getDPListService = async (projectID,castID) => {
    return await get(`/project/${projectID}/cast/${castID}/dp`);
}

export const getHouseListService = async (projectID,castID,dpID) => {
    return await get(`/project/${projectID}/cast/${castID}/dp/${dpID}/house`);
}

export const getCountryService = async () => {
    return await get(COUNTRY_URL);
}

export const addCountryService = async (data) => {
    return await post(COUNTRY_URL,{name:data.name});
}

export const getCityService = async (CountryID) => {
    return await get(`/country/${CountryID}/city`);
}

export const addCityService = async (data) => {
    return await post(`/country/${data.countryID}/city`,{name:data.name});
}

export const getCompanyService = async (CountryID) => {
    return await get(`/country/${CountryID}/telecom_company`);
}

export const AddCompanyService = async (data) => {
    return await post(`/country/${data.countryID}/telecom_company`,{name:data.name});
}

export const getPDFService = async (id) => {
    const response = await get(`/house/${id}/report`, {
        timeout: 0,
        params: {
            language: getCookie("I18N_LANGUAGE"),
        }
    });
    return response.data;
}