import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Col, Container, Nav, Navbar, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import DataGrid, {Button, Column, Grouping, LoadPanel, Pager, Paging, StateStoring,} from "devextreme-react/data-grid"
import {getProjectData} from "../../store/projects/actions";
import {getUserListService, getUserRoleService} from "../../services/panel/userService";
import classnames from 'classnames';
import AddUser from "../userManagement/addUser";
import UserGrids from "./user/userGrids";


const AdminPanel = (props) => {
    const navigate = useHistory();
    const dispatch = useDispatch()
    const [userList, setUserList] = useState(null)
    const [activeTab, setActiveTab] = useState("1");
    const [currentTab, setCurrentTab] = useState("1")
    const [userRole, setUserRole] = useState(null)
    const [isAdd, setIsAdd] = useState(false)
    const [userRoleData, setUserRoleData] = useState(null)

    const Submit = (value) => {
        if (!value) return;
        getUserListService().then((response) => {
            setUserList(response.data)
        })
    }
    useEffect(() => {
        getUserRoleService().then((res) => {
            setUserRoleData(res)
        })
        getUserListService().then((repsonse) => {
            setUserList(repsonse.data)
        })
        dispatch(getProjectData())
    }, [])

    const {projectFile} = useSelector(state => ({
        projectFile: state.Project.projectFile
    }))

    const Details = (row) => {
        navigate.push({
            pathname: "/projects/user",
            search: `?projectID=${row.id}&projectCode=${row.code}&desc=${row.description}`,
        })
    }

    const cast = (row) => {
        navigate.push({
            pathname: "/projects/cast",
            search: `?projectID=${row.id}`,
        })
    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid className="dash">
                <div>
                </div>
                <Row className="mt-3">
                    <Col xl={6} className="mt-2">
                        <div className="UserList">
                            <div className="shadow-sm p-3 rounded-4 mt-2 bg-white DataGrid">
                                <div className="Table shadow-sm p-3 rounded-4">
                                    <Navbar expand="md" className="nav-tabs-custom">
                                        <Nav role="tablist" className="nav nav-tabs border-0 pt-2">
                                            <NavItem className="font-size-14">
                                                <NavLink
                                                    className={classnames({active: currentTab === "1",})}
                                                    onClick={() => {
                                                        setActiveTab("1")
                                                        setCurrentTab("1")
                                                    }}
                                                >
                                                    {props.t("ALL")}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="font-size-14">
                                                <NavLink className={classnames({active: currentTab === "2",})}
                                                         onClick={() => {
                                                             setActiveTab("2")
                                                             setCurrentTab("2")
                                                             setUserRole("HOUSE")
                                                         }}>
                                                    House
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="font-size-14">
                                                <NavLink className={classnames({active: currentTab === "3",})}
                                                         onClick={() => {
                                                             setActiveTab("2")
                                                             setCurrentTab("3")
                                                             setUserRole("RACKET")
                                                         }}>
                                                    Racket
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="font-size-14">
                                                <NavLink className={classnames({active: currentTab === "4",})}
                                                         onClick={() => {
                                                             setActiveTab("2")
                                                             setCurrentTab("4")
                                                             setUserRole("BLASEN")
                                                         }}>
                                                    Blasen
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="font-size-14">
                                                <NavLink className={classnames({active: currentTab === "5",})}
                                                         onClick={() => {
                                                             setActiveTab("2")
                                                             setCurrentTab("5")
                                                             setUserRole("LASSEN")
                                                         }}>
                                                    Lassen
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="font-size-14">
                                                <NavLink className={classnames({active: currentTab === "6",})}
                                                         onClick={() => {
                                                             setActiveTab("2")
                                                             setCurrentTab("6")
                                                             setUserRole("HAS")
                                                         }}>
                                                    Has
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <Nav className="ml-auto nav nav-tabs border-0 pt-2">
                                            <NavItem>
                                                <div className="btn btn-gray btn-sm mt-1 btn-semi-bold me-2"
                                                     onClick={() => {
                                                         navigate.push({pathname: "/users",})
                                                     }}>
                                                    <i className="fa fa-list-ul font-size-14 me-2"/>{props.t("User List")}
                                                </div>
                                            </NavItem>
                                            <NavItem>
                                                <div className="btn btn-green btn-sm mt-1 btn-semi-bold"
                                                     onClick={() => {
                                                         setIsAdd(true)
                                                     }}>
                                                    <i className="fa fa-plus font-size-14 me-2"/>{props.t("Add User")}
                                                </div>
                                            </NavItem>
                                        </Nav>
                                    </Navbar>
                                    {userList && (
                                        <TabContent className="mt-2" activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <DataGrid
                                                    allowColumnReordering={true}
                                                    id="dashboardUser"
                                                    showBorders={false}
                                                    className="bg-white dashboard-grid"
                                                    dataSource={userList}
                                                    columnAutoWidth={true}
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    scrolling={false}
                                                >
                                                    <StateStoring enabled={true} type="localStorage" storageKey="dashboardUser" />

                                                    <LoadPanel enabled={false}/>
                                                    <Paging defaultPageSize={5}/>
                                                    <Pager showInfo={false} showNavigationButtons={true}/>
                                                    <Grouping contextMenuEnabled={true}/>
                                                    <Column dataField="name" caption={props.t("Name")}
                                                            cssClass="vertical-middle"
                                                            dataType="string"></Column>
                                                    <Column dataField="lastname" caption={props.t("LastName")}
                                                            cssClass="vertical-middle"
                                                            dataType="string"></Column>
                                                    <Column dataField="email" caption={props.t("E-mail")}
                                                            cssClass="vertical-middle"
                                                            dataType="string"></Column>
                                                    <Column dataField="role" caption={props.t("User Role")}
                                                            cssClass="vertical-middle"
                                                            dataType="string"></Column>
                                                </DataGrid>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <UserGrids userList={userList} user={userRole} />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} className="mt-2">
                        <div className="UserList">
                            <div className="shadow-sm p-3 rounded-4 mt-2 bg-white DataGrid">
                                <div className="Table shadow-sm p-3 rounded-4">
                                    <Navbar expand="md" className="nav-tabs-custom">
                                        <Nav role="tablist" className="nav nav-tabs border-0 pt-2">
                                        </Nav>
                                        <Nav className="ml-auto nav nav-tabs border-0 pt-2">
                                            <NavItem>
                                                <div className="btn btn-gray btn-sm mt-1 btn-semi-bold me-2"
                                                     onClick={() => {
                                                         navigate.push({
                                                             pathname: "/projects",
                                                         })
                                                     }}><i
                                                    className="fa fa-list-ul font-size-14 me-2"/>{props.t("Project List")}
                                                </div>
                                            </NavItem>
                                            <NavItem>
                                                <div className="btn btn-green btn-sm mt-1 btn-semi-bold"
                                                     onClick={() => {
                                                         navigate.push({
                                                             pathname: "/project/add",
                                                         })
                                                     }}><i
                                                    className="fa fa-plus font-size-14 me-2"/>{props.t("Add Project")}
                                                </div>
                                            </NavItem>
                                        </Nav>
                                    </Navbar>
                                    <DataGrid
                                        allowColumnReordering={true}
                                        id="dashboardProject"
                                        showBorders={false}
                                        className="bg-white mt-2"
                                        dataSource={projectFile?.data}
                                        columnAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        scrolling={false}
                                    >
                                        <StateStoring enabled={true} type="localStorage" storageKey="dashboardProjects" />
                                        <LoadPanel enabled={false}/>
                                        <Paging defaultPageSize={7}/>
                                        <Pager showInfo={false} showNavigationButtons={true}/>
                                        <Grouping contextMenuEnabled={true}/>
                                        <Column dataField="code" caption={props.t("Project Code")}
                                                cssClass="bold text-center vertical-middle"
                                                dataType="string"></Column>
                                        <Column dataField="description" caption={props.t("Description")}
                                                cssClass="vertical-middle"
                                                dataType="string"></Column>
                                        <Column dataField="created_at" caption={props.t("Created At")}
                                                dataType="date" cssClass="vertical-middle"></Column>
                                        <Column
                                            dataField="Details"
                                            caption={props.t("Transaction")}
                                            width={150}
                                            type="buttons"
                                        >
                                            <Button
                                                cssClass="btn btn-green btn-sm font-size-12"
                                                text={props.t("Details")}
                                                onClick={(object) => {
                                                    Details(object.row.data)
                                                }}>
                                            </Button>
                                            <Button
                                                cssClass="btn btn-orange btn-sm font-size-12"
                                                text={props.t("Cast")}
                                                onClick={(object) => {
                                                    cast(object.row.data)
                                                }}>
                                            </Button>
                                        </Column>
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <AddUser show={isAdd} toggle={() => setIsAdd(false)} isAdding={(value) => Submit(value)}
                         userRoleData={userRoleData}/>
            </Container>
        </div>
    </React.Fragment>)
}

AdminPanel.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AdminPanel)
)