import React, {useState} from 'react';
import {
    Alert,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {loginUser} from 'store/actions';
import logomavi from "../assets/images/FiberApp.png";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

const LoginCredential = (props) => {

    const dispatch = useDispatch();
    const [password, setPassword] = useState("password");

    const {error} = useSelector(state => ({
        error: state.Login.error
    }));
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "" || '',
            password: "" || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(props.t("Please Email")),
            password: Yup.string().required(props.t("Please Password")),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.history, props));
        }
    });

    return (
        <React.Fragment>
            <div className="my-auto container">
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden shadow-sm rounded-5">
                            <div className="bg-green bg-soft">
                                <Row>
                                    <Col className="col-7">
                                        <div className="text-white p-4">
                                            <h5 className="text-white">{props.t("Welcome")}</h5>
                                            <p>{props.t("Enter Your Information to Login.")}</p>
                                        </div>
                                    </Col>
                                    <Col className="col-5 align-self-end mb-2">
                                        <img src={logomavi} alt="" className="img-fluid"/>
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <div className="p-2 mt-4">
                                    <Form className="form-horizontal"
                                          onSubmit={(e) => {
                                              e.preventDefault();
                                              validation.handleSubmit();
                                              return false;
                                          }}
                                    >
                                        {error ? <Alert color="danger">{error}</Alert> : null}
                                        <div className="mb-3 border-bottom-green">
                                            <Label className="form-label text-black">{props.t("E-mail")}</Label>
                                            <InputGroup className="border-0">
                                                <InputGroupText className="bg-white border-0">
                                                    <i className="fa fa-user"/>
                                                </InputGroupText>
                                                <Input
                                                    name="email"
                                                    className="form-control border-0"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback
                                                        type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </div>

                                        <div className="mb-3 border-bottom-green">
                                            <Label className="form-label text-black">{props.t("Password")}</Label>
                                            <InputGroup className="border-0">
                                                <InputGroupText className="bg-white border-0">
                                                    <i className="fa fa-lock"/>
                                                </InputGroupText>
                                                <Input
                                                    name="password"
                                                    className="form-control border-0"
                                                    value={validation.values.password || ""}
                                                    type={password}
                                                    placeholder="Enter Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }

                                                />
                                                <InputGroupText className="bg-white border-0">
                                                    <i className={password === "password" ? "fa fa-eye" : "fa fa-eye-slash"}
                                                       onClick={() => {
                                                           password === "password" ? setPassword("text") : setPassword("password")

                                                       }}/>
                                                </InputGroupText>
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback
                                                        type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                            </InputGroup>

                                        </div>
                                        <div className="mt-5 d-grid">
                                            <button
                                                className="btn btn-w btn-green btn-block"
                                                type="submit"
                                            >
                                                {props.t("Login")}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

LoginCredential.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(LoginCredential)
)