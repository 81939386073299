//Admin Panel
export const GET_USER_LIST = "/user";
export const USER_ROLE ="/user/role"
export const GET_PROJECT_LIST = "/project"
export const LOGIN_URL = "/auth/login"
export const CAST_URL = "/cast"
export const DP_URL = "/dp"
export const HOUSE_URL ="/house"
export const ADDRESS_URL ="/address"
export const TERMIN_URL ="/termin"
export const COUNTRY_URL ="/country"
export const GET_REPORT_URL ="/report"
export const GET_COMPANY_URL ="/company"
export const ADD_COLOR_URL ="/color"
export const GET_HOUSE_REPORT_URL ="/report/house"
export const GET_RACKET_REPORT_URL ="/report/racket"
export const GET_BLASEN_REPORT_URL ="/report/blasen-house"
export const GET_BLASEN_DP_REPORT_URL ="/report/blasen-dp"
export const GET_HAS_REPORT_URL ="/report/has"
export const GET_LASSEN_REPORT_URL ="/report/lassen"




