import {call, put, takeEvery} from "redux-saga/effects"

// Login Redux States
import {apiFail, apiSuccess,} from "../dashboard/actions"
import {GET_PROJECT_LIST} from "./actionTypes";
import {getProjectListService} from "../../services/panel/projectService";

function* getProjectListData(data) {
    try {
        const getProjectListServiceResponse = yield call(getProjectListService,data);
        yield put(apiSuccess(GET_PROJECT_LIST, getProjectListServiceResponse));
    } catch (error) {
        yield put(apiFail(error))
    }
}

function* projectSaga() {
    yield takeEvery(GET_PROJECT_LIST, getProjectListData);
}

export default projectSaga;
