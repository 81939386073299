import {ADD_COLOR_URL} from "helpers/url_helper";
import axios from "axios"
const { post,get,del,getFile } = require("helpers/api_helper");

export const addColorService = async (data) => {
    return await post(ADD_COLOR_URL,data);
}

export const downloadRarService = async (data) => {
    return await getFile("image/download-rar",{responseType: 'blob', timeout: 0});
}

export const createZipService = async (data) => {
    const result = await get("image/download-all",{timeout: 0});
    if(result.status) {
        return downloadRarService() ;
    }
}
