import React from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {addUserService} from "../../services/panel/userService";
import {error, notify} from "../../components/toastify"


const AddUser = (props) => {
    const {show, toggle , isAdding, userRoleData } = props;
    const addUser = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: "" || '',
            password: "" || '',
            Role: "" | "",
            lastName: "" | "",
            name: "" | ""
        },
        validationSchema: Yup.object({
            Role: Yup.string().required(props.t("Please Role")),
            lastName: Yup.string().required(props.t("Please LastName")),
            name: Yup.string().required(props.t("Please Name")),
            email: Yup.string().required(props.t("Please Email")),
            password: Yup.string().required(props.t("Please Password")),
        }),
        onSubmit: (values, {resetForm}) => {
            addUserService(values).then((res) => {
                resetForm()
                if(!res.status) return error(res.message)
                toggle()
                notify()
                isAdding(true)
            })
        }
    });

    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="md" aria-labelledby="contained-modal-title-vcenter" centered>

                <Form className="form-horizontal" onSubmit={(e) => {
                    e.preventDefault();
                    addUser.handleSubmit();
                }}>
                    <ModalHeader toggle={toggle}><span className="float-center">{props.t("Add New User")}</span></ModalHeader>
                    <ModalBody>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("Name")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-user"/>
                            </InputGroupText>
                            <Input
                                name="name"
                                className="form-control border-0"
                                placeholder={props.t("Please Name")}
                                type="text"
                                maxLength={50}
                                onChange={addUser.handleChange}
                                onBlur={addUser.handleBlur}
                                value={addUser.values.name || ""}
                                required={true}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("LastName")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-user"/>
                            </InputGroupText>
                            <Input
                                name="lastName"
                                className="form-control border-0"
                                placeholder={props.t("Please LastName")}
                                type="text"
                                maxLength={50}
                                onChange={addUser.handleChange}
                                onBlur={addUser.handleBlur}
                                value={addUser.values.lastName || ""}
                                required={true}

                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("E-mail")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-envelope"/>
                            </InputGroupText>
                            <Input
                                name="email"
                                className="form-control border-0"
                                maxLength={50}
                                placeholder={props.t("Please Email")}
                                type="email"
                                onChange={addUser.handleChange}
                                onBlur={addUser.handleBlur}
                                value={addUser.values.email || ""}
                                required={true}

                            />

                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("Password")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-lock"/>
                            </InputGroupText>
                            <Input
                                name="password"
                                className="form-control border-0"
                                value={addUser.values.password || ""}
                                type="password"
                                maxLength={50}
                                placeholder={props.t("Please Password")}
                                onChange={addUser.handleChange}
                                onBlur={addUser.handleBlur}
                                required={true}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("User Role")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-user-edit"/>
                            </InputGroupText>
                            <Input
                                name="Role"
                                className="form-control border-0"
                                placeholder={props.t("Please Role")}
                                type="select"
                                onChange={addUser.handleChange}
                                onBlur={addUser.handleBlur}
                                value={addUser.values.Role}
                                required={true}>
                                <option value="SelectRole">{props.t("Select Role")}</option>
                                {userRoleData !== null ?
                                    userRoleData.map((item, index) => {return (<option key={index} value={item.name}>{item.name}</option>)})
                                    :
                                    ""
                                }
                            </Input>
                        </InputGroup>
                    </FormGroup>
                    </ModalBody>
                    <ModalFooter className="form-footer">
                        <Button
                            block
                            color="success"
                            className="btn-green"
                            type="submit"
                        >
                            {props.t("Add User")}
                        </Button>
                    </ModalFooter>
                </Form>
        </Modal>
    </React.Fragment>)
}

AddUser.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AddUser)
)