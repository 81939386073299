import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useFormik} from "formik";
import Select from "react-select";
import {getCityService, getCompanyService, getCountryService} from "../../services/panel/projectService";
import {
    getCountryCityStreetService,
    getCountryProjectService,
    getProjectCastDpService,
    getProjectCastService
} from "../../services/panel/reportService";
import {getUserListService} from "../../services/panel/userService";


const FilterModal = (props) => {
    const {show, toggle,onSubmit} = props;
    const [countries, setCountries] = useState([]);
    const [user, setUser] = useState([]);
    const [cities, setCities] = useState([]);
    const [street, setStreet] = useState([]);
    const [company, setCompany] = useState([]);
    const [project, setProject] = useState([]);
    const [cast, setCast] = useState([]);
    const [dp, setDp] = useState([]);
    const filterForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            user_id: null,
            country_id: null,
            city_id: null,
            telecom_company_id: null,
            street_name: null,
            project_id: null,
            cast_id: null,
            dp_id: null,
            end_date: null,
            start_date: null,
        },
        onSubmit: (values, {resetForm}) => {
            toggle();
            onSubmit(values)
            resetForm()
        }
    });
    const handleCountryChange = (selectedOption) => {
        filterForm.setFieldValue('country_id', selectedOption);
        filterForm.setFieldValue('city_id', null);
        filterForm.setFieldValue('project_id', null);
        filterForm.setFieldValue('telecom_company_id', null);
        filterForm.setFieldValue('street_name', null);
        setCities([])
        setStreet([])
        setCompany([])
        getCityService(selectedOption.value).then((response) => {
            const citiesOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name, country: item.country_id}
            })
            setCities(citiesOptions)
        })
        getCountryProjectService(selectedOption.value).then((response) => {
            const projectOptions = response.data?.map((item) => {
                return {value: item.id, label: item.code, country: selectedOption.value}
            })
            setProject(projectOptions)
        })
    }
    const handleCityChange = (selectedOption) => {
        filterForm.setFieldValue('city_id', selectedOption);
        filterForm.setFieldValue('telecom_company_id', null);
        filterForm.setFieldValue('street_name', null);
        setCompany([])
        setStreet([])
        getCompanyService(selectedOption.country).then((response) => {
            const companyOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name}
            })
            setCompany(companyOptions)
        })
        getCountryCityStreetService(selectedOption.country, selectedOption.value).then((response) => {
            const streetOptions = response.data?.map((item) => {
                return {value: item.street, label: item.street}
            })
            setStreet(streetOptions)
        })
    }
    const handleProjectChange = (selectedOption) => {
        filterForm.setFieldValue('project_id', selectedOption);
        filterForm.setFieldValue('cast_id', null);
        filterForm.setFieldValue('dp_id', null);
        setCast([])
        setDp([])
        getProjectCastService(selectedOption.value).then((response) => {
            const castOption = response.data?.map((item) => {
                return {value: item.id, label: item.code, project: selectedOption.value}
            })
            setCast(castOption)
        })
    }
    const handleCastChange = (selectedOption) => {
        filterForm.setFieldValue('cast_id', selectedOption);
        filterForm.setFieldValue('dp_id', null);
        setDp([])
        getProjectCastDpService(selectedOption.project,selectedOption.value).then((response) => {
            const dpOption = response.data?.map((item) => {
                return {
                    value: item.id,
                    label: item.code,
                }
            })
            setDp(dpOption)
        })
    }

    useEffect(() => {
        getCountryService().then((response) => {
            const countriesOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name}
            })
            setCountries(countriesOptions)
        })
        getUserListService().then((response) => {
            const userOptions = response.data?.map((item) => {
                return {value: item.id, label: `${item.name}  ${item.lastname}`}
            })
            setUser(userOptions)
        })
    }, [])
    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Form className="form-horizontal" onSubmit={(e) => {
                e.preventDefault();
                filterForm.handleSubmit();
            }}>
                <ModalHeader toggle={toggle}><span
                    className="float-center">{props.t("Filter Reports")}</span></ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="User">{props.t("User")}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="User"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(selectedOptions)=>{
                                filterForm.setFieldValue('user_id', selectedOptions);
                            }}
                            onBlur={filterForm.handleBlur}
                            options={user}
                            value={filterForm.values.user_id}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="country">{props.t("Country")}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="country"
                            isSearchable={true}
                            isClearable={true}
                            onChange={handleCountryChange}
                            onBlur={filterForm.handleBlur}
                            options={countries}
                            value={filterForm.values.country_id}
                        />
                    </FormGroup>
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <label htmlFor="city">{props.t("City")}</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="city"
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={handleCityChange}
                                    onBlur={filterForm.handleBlur}
                                    options={cities}
                                    value={filterForm.values.city_id}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <label htmlFor="company">{props.t("Telecom Company")}</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="company"
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                        filterForm.setFieldValue('telecom_company_id', selectedOption)
                                    }}
                                    onBlur={filterForm.handleBlur}
                                    options={company}
                                    value={filterForm.values.telecom_company_id}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <label htmlFor="street">{props.t("Street")}</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="street"
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                        filterForm.setFieldValue('street_name', selectedOption)
                                    }}
                                    onBlur={filterForm.handleBlur}
                                    options={street}
                                    value={filterForm.values.street_name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <label htmlFor="project">{props.t("Project")}</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="project"
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={handleProjectChange}
                                    onBlur={filterForm.handleBlur}
                                    options={project}
                                    value={filterForm.values.project_id}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <label htmlFor="cast">{props.t("Cast")}</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="cast"
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={handleCastChange}
                                    onBlur={filterForm.handleBlur}
                                    options={cast}
                                    value={filterForm.values.cast_id}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <label htmlFor="DP">{props.t("DP")}</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="DP"
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                        filterForm.setFieldValue('dp_id', selectedOption)
                                    }}
                                    onBlur={filterForm.handleBlur}
                                    options={dp}
                                    value={filterForm.values.dp_id}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup className="border-bottom-green">
                                <label htmlFor="start_date">{props.t("Start Date")}</label>
                                <Input
                                    className="form-control"
                                    type="date"
                                    name="start_date"
                                    onChange={filterForm.handleChange}
                                    onBlur={filterForm.handleBlur}
                                    value={filterForm.values.start_date}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup className="border-bottom-green">
                                <label htmlFor="end_date">{props.t("End Date")}</label>
                                <Input
                                    className="form-control"
                                    type="date"
                                    name="end_date"
                                    onChange={filterForm.handleChange}
                                    onBlur={filterForm.handleBlur}
                                    value={filterForm.values.end_date}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="form-footer">
                    <Button
                        block
                        color="success"
                        className="btn-green"
                        type="submit"
                    >
                        {props.t("Apply")}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    </React.Fragment>)
}

FilterModal.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(FilterModal)
)