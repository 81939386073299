import i18n from "../../i18n.js";

const t = i18n.t;
export const dummyFormData = () => [{
    
    colSpan: 1,
    label: {text: t("Customer Name")},
    editorType: "dxTextBox",
    dataField: "customer_name",
    validationRules: [{type: "required",}]
}, {
    colSpan: 1,
    label: {text: t("Customer LastName")},
    editorType: "dxTextBox",
    dataField: "customer_lastname",
    validationRules: [{type: "required",}]
}, {
    colSpan: 2,
    label: {text: t("Customer Email")},
    editorType: "dxTextBox",
    dataField: "customer_email",
    editorOptions: {mode: "email"},
    validationRules: [{type: "required",}]
}, {
    label: {text: t("Start Date")},
    dataField: 'startDate',
    editorType: 'dxDateBox',
    validationRules: [{type: "required",}],
    editorOptions: {
        width: '100%',
        type: 'datetime',
    },
}, {
    label: {text: t("End Date")},
    name: 'endDate',
    dataField: 'endDate',
    editorType: 'dxDateBox',
    validationRules: [{type: "required",}],

    editorOptions: {
        width: '100%',
        type: 'datetime',
    },
}, {
    colSpan: 1,
    label: {text: t("Customer Phone")},
    editorType: "dxTextBox",
    dataField: "customer_phone",
    validationRules: [{type: "required",}]
}, {
    colSpan: 1,
    label: {text: t("Customer Home Phone")},
    editorType: "dxTextBox",
    dataField: "customer_home_phone",
}, {
    colSpan: 2,
    label: {text: t("User")},
    editorType: "dxSelectBox",
    dataField: "user_id",
    validationRules: [{type: "required",}],
    editorOptions: {
        items: [],
        displayExpr: "label",
        searchEnabled: true,
        valueExpr: "value",

    }
}, {
    colSpan: 2,
    label: {text: t("House")},
    editorType: "dxSelectBox",
    dataField: "house_id",
    validationRules: [{type: "required",}],
    editorOptions: {
        items: [],
        searchEnabled: true,
        displayExpr: "label",
        valueExpr: "value",
        onValueChanged: function (args) {
        }
    }
}, {
    colSpan: 2,
    label: {text: t("Status")},
    editorType: "dxSelectBox",
    dataField: "status",
    editorOptions: {
        items: [],
        displayExpr: "label",
        defaultValue: 0,
        valueExpr: "value",
        onValueChanged: function (args) {
        }
    }
},
    {
        colSpan: 2,
        label: {text: t("Termin Note")},
        editorType: "dxTextArea",
        dataField: "terminNote",
    }
];