import {
    GET_BLASEN_DP_REPORT_URL,
    GET_BLASEN_REPORT_URL,
    GET_HAS_REPORT_URL,
    GET_HOUSE_REPORT_URL, GET_LASSEN_REPORT_URL,
    GET_RACKET_REPORT_URL
} from "helpers/url_helper";

const { post,get,del } = require("helpers/api_helper");

export const getHouseReportService = async (params) => {

    const response = await get(GET_HOUSE_REPORT_URL,{params});
    return response.data;
}
export const getRacketReportService = async (params) => {

    const response = await get(GET_RACKET_REPORT_URL,{params});
    return response.data;
}
export const getBlasenReportService = async (params) => {

    const response = await get(GET_BLASEN_REPORT_URL,{params});
    return response.data;
}

export const getBlasenDPReportService = async (params) => {

    const response = await get(GET_BLASEN_DP_REPORT_URL,{params});
    return response.data;
}

export const getHasReportService = async (params) => {

    const response = await get(GET_HAS_REPORT_URL,{params});
    return response.data;
}

export const getLassenReportService = async (params) => {

    const response = await get(GET_LASSEN_REPORT_URL,{params});
    return response.data;
}

export const getCountryProjectService = async (countryID) => {
    const url = `/country/${countryID}/project`;
    return await get(url);
}

export const getCountryCityStreetService = async (countryID,cityID) => {
    const url = `/country/${countryID}/city/${cityID}/street`;
    return await get(url);
}

export const getProjectCastService = async (projectID) => {
    const url = `/project/${projectID}/cast`;
    return await get(url);
}

export const getProjectCastDpService = async (projectID,castID) => {
    const url = `/project/${projectID}/cast/${castID}/dp`;
    return await get(url);
}

export const filteredReports = async (data) => {
    // const queryParams  = {
    //     cast_id: data?.cast_id?.value,
    //     city_id: data?.city_id?.value,
    //     country_id: data?.country_id?.value,
    //     dp_id: data?.dp_id?.value,
    //     end_date: data?.end_date,
    //     project_id: data?.project_id?.value,
    //     start_date: data?.start_date,
    //     street_name: data?.street_name?.value,
    //     telecom_company_id: data?.telecom_company_id?.value,
    //     user_id: data?.user_id?.value,
    // }
    const url = `/report`;
    const response = await get(url,{params: data });
    if(!response.data) return response;

    return {
        home: [
            {title: "Canceled House", count: response.data.count_house_cancelled, status: "cancelled"},
            {title: "Waiting House", count: response.data.count_house_pending,status: "pending"},
            {title: "ACCESSNET House", count: response.data.count_house_completed,status: "completed"},
            {title: "HP+ House", count: response.data.count_house_defective, status: "defective"},
        ],
        homeCount: Number(response.data.count_house_cancelled) + Number(response.data.count_house_pending) + Number(response.data.count_house_completed) + Number(response.data.count_house_defective),
        racket: [
            {title: "Canceled Racket", count: response.data.count_racket_cancelled , status: "cancelled"},
            {title: "Waiting Racket", count: response.data.count_racket_pending, status: "pending"},
            {title: "ACCESSNET Racket", count: response.data.count_racket_completed, status: "completed"},
            {title: "HP+ Racket", count: response.data.count_racket_defective, status: "defective"},
        ],
        racketCount: Number(response.data.count_racket_cancelled) + Number(response.data.count_racket_pending) + Number(response.data.count_racket_completed) + Number(response.data.count_racket_defective),
        blasenHouse: [
            {title: "Canceled Blasen House", count: response.data.count_blasen_house_cancelled, status: "cancelled"},
            {title: "Waiting Blasen House", count: response.data.count_blasen_house_pending, status: "pending"},
            {title: "ACCESSNET Blasen House", count: response.data.count_blasen_house_completed, status: "completed"},
            {title: "HP+ Blasen House", count: response.data.count_blasen_house_defective, status: "defective"},
        ],
        blasenHouseCount: Number(response.data.count_blasen_house_cancelled) + Number(response.data.count_blasen_house_pending) + Number(response.data.count_blasen_house_completed) + Number(response.data.count_blasen_house_defective),
        blasenDP: [
            {title: "Canceled Blasen DP", count: response.data.count_blasen_dp_cancelled, status: "cancelled"},
            {title: "Waiting Blasen DP", count: response.data.count_blasen_dp_pending, status: "pending"},
            {title: "ACCESSNET Blasen DP", count: response.data.count_blasen_dp_completed, status: "completed"},
            {title: "HP+ Blasen DP", count: response.data.count_blasen_dp_defective, status: "defective"},
        ],
        blasenDPCount: Number(response.data.count_blasen_dp_cancelled) + Number(response.data.count_blasen_dp_pending) + Number(response.data.count_blasen_dp_completed) + Number(response.data.count_blasen_dp_defective),
        LassenHouse: [
            {title: "Canceled Lassen House", count: response.data.count_lassen_house_cancelled, status: "cancelled"},
            {title: "Waiting Lassen House", count: response.data.count_lassen_house_pending, status: "pending"},
            {title: "ACCESSNET Lassen House", count: response.data.count_lassen_house_complete, status: "completed"},
            {title: "HP+ Lassen House", count: response.data.count_lassen_house_defective, status: "defective"},
        ],
        LassenHouseCount: Number(response.data.count_lassen_house_cancelled) + Number(response.data.count_lassen_house_pending) + Number(response.data.count_lassen_house_complete) + Number(response.data.count_lassen_house_defective),
        lassenDP: [
            {title: "Canceled Lassen DP", count: response.data.count_lassen_dp_cancelled, status: "cancelled"},
            {title: "Waiting Lassen DP", count: response.data.count_lassen_dp_pending, status: "pending"},
            {title: "ACCESSNET Lassen DP", count: response.data.count_lassen_dp_complete, status: "completed"},
            {title: "HP+ Lassen DP", count: response.data.count_lassen_dp_defective, status: "defective"},
        ],
        lassenDPCount: Number(response.data.count_lassen_dp_cancelled) + Number(response.data.count_lassen_dp_pending) + Number(response.data.count_lassen_dp_complete) + Number(response.data.count_lassen_dp_defective),
        lassenCast: [
            {title: "Canceled Lassen Cast", count: response.data.count_lassen_cast_cancelled, status: "cancelled"},
            {title: "Waiting Lassen Cast", count: response.data.count_lassen_cast_pending, status: "pending"},
            {title: "ACCESSNET Lassen Cast", count: response.data.count_lassen_cast_complete, status: "completed"},
            {title: "HP+ Lassen Cast", count: response.data.count_lassen_cast_defective, status: "defective"},
        ],
        lassenCastCount: Number(response.data.count_lassen_cast_cancelled) + Number(response.data.count_lassen_cast_pending) + Number(response.data.count_lassen_cast_complete) + Number(response.data.count_lassen_cast_defective),
        has: [
            {title: "Canceled Has", count: response.data.count_has_cancelled, status: "cancelled"},
            {title: "Waiting Has", count: response.data.count_has_pending, status: "pending"},
            {title: "ACCESSNET Has", count: response.data.count_has_completed, status: "completed"},
            {title: "HP+ Has", count: response.data.count_has_defective, status: "defective"},
        ],
        hasCount: Number(response.data.count_has_cancelled) + Number(response.data.count_has_pending) + Number(response.data.count_has_completed) + Number(response.data.count_has_defective),
    };}
