import {API_FAIL, API_SUCCESS, GET_PROJECT_LIST, SET_EXCEL_DATA, SET_EXCEL_FILE} from "./actionTypes";

const INIT_STATE = {
    excelData: [],
    error: {},
    excelFile: null,
    projectFile: null
};

const Project = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_PROJECT_LIST:
                    return {
                        ...state,
                        projectFile: action.payload.data,
                    }
                default:
                    return state
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_PROJECT_LIST:
                    return {
                        ...state,
                        projectFile: [],
                        error: action.payload.error,
                    }
                default:
                    return state;
            }
        case SET_EXCEL_DATA:
            return {
                ...state,
                excelData: action.payload,
            };
        case SET_EXCEL_FILE:
            return {
                ...state,
                excelFile: action.payload,
            };
        default:
            return state;
    }
};

export default Project;
