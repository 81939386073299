import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {showRightSidebarAction, toggleLeftmenu} from "../../store/actions";
import ProfileMenu from "./TopbarDropdown/ProfileMenu";
import {withTranslation} from "react-i18next";
import FiberApp from "../../assets/images/FiberApp.png"
import getUser from "../../helpers/get_user";
import LanguageDropdown from "./TopbarDropdown/LanguageDropdown"

const Header = props => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const _user = getUser();
    setUser(_user)
  }, [])

  return (
      <React.Fragment>
        {user !== null ?
            <header id="page-topbar" className="bg-green">
              <div className="navbar-header dash">
                <div className="d-flex">
                  <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                <img src={FiberApp} alt="" height="50" />
                </span>
                      <span className="logo-lg">
                <img src={FiberApp} alt="" height="58" />
                </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                <img src={FiberApp} alt="" height="50" />
                </span>
                      <span className="logo-lg">
                <img src={FiberApp} alt="" height="58" />
                </span>
                    </Link>
                  </div>
                  <div className="app-search d-none d-lg-block">
                    <span className="position-relative font-size-20 text-white">{props.t("FiberApp")}</span>
                  </div>
                  <button
                      type="button"
                      className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                      data-toggle="collapse"
                      onClick={() => {
                        props.toggleLeftmenu(!props.leftMenu);
                      }}
                      data-target="#topnav-menu-content"
                  >
                    <i className="fa fa-fw fa-bars" />
                  </button>

                </div>

                <div className="d-flex">
                  <LanguageDropdown />
                  <ProfileMenu />
                </div>
              </div>
            </header>
            :
            <header></header>
        }

      </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
