import {combineReducers} from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import User from "./user/reducer"

//Projects
import Project from "./projects/reducer"
import Dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
  // public
  Dashboard,
  Layout,
  Login,
  Profile,
  Project,
  User
})

export default rootReducer
