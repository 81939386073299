const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
  SCROLLABLE: "scrollable",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
}

export {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,

}
