import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App.js";
import * as serviceWorker from "./serviceWorker.js";
import {BrowserRouter} from "react-router-dom";
import "./i18n.js";
import {Provider} from "react-redux";

import store from "./store";
import {ToastContainer} from "react-toastify";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App/>
          <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
          />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
// Object.assign({}, state.Layout);
serviceWorker.unregister()
