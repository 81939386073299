import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button as RsButton, ButtonGroup, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    Grouping,
    GroupPanel,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
} from "devextreme-react/data-grid"
import {exportDataGrid} from 'devextreme/pdf_exporter';
import {jsPDF} from 'jspdf';
import {
    getBlasenDPReportService,
    getBlasenReportService,
    getHasReportService,
    getHouseReportService,
    getLassenReportService,
    getRacketReportService
} from "../../services/panel/reportService";
import DataGridLoadPanel from "devextreme-react/load-panel";
import {badgeColorStatusValue} from "../../constants/badgecolor";
import ImageModal from "../Projects/projectHouseList/imageModal";
import {formatDate} from "../../helpers/formatDate";

const exportFormats = ['pdf', 'excel'];

const Details = (props) => {
    const position = {of: '#reports', at: 'center', my: 'center'};
    const navigate = useHistory();
    const [detailsData, setDetailsData] = useState(null)
    const [detailsTitle, setDetailsTitle] = useState("")
    const [storageKey, setStorageKey] = useState("")
    const [allImage, setAllImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageModal, setImageModal] = useState(false);
    const [title, setTitle] = useState("");
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let filterParam = params.get('filter');
    let filters = JSON.parse(filterParam);
    const onExporting = (e) => {
        const {component} = e;
        if (e.format === "excel") {
            component.option("export.fileName", "Reports.xlsx");
            component.exportToExcel();
            e.cancel = true;
        } else if (e.format === "pdf") {
            const doc = new jsPDF("p", "mm", "a2");
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                indent: 1,
            }).then(() => {
                doc.save("Reports.pdf");
            });
            e.cancel = true;
        }
    };

    useEffect(() => {
        if (params.get("type") === undefined) return navigate.push({pathname: "/reports"})
        switch (params.get("type")) {
            case "House":
                setDetailsTitle(props.t("House Report"));
                setStorageKey("houseReport")
                getHouseReportService(filters).then((response) => {
                    if(!response) return setDetailsData([])
                    setDetailsData(response)
                })
                break;
            case "Blasen":
                setDetailsTitle(props.t("Blasen House Report"));
                setStorageKey("blasenReport")
                getBlasenReportService(filters).then((response) => {
                    if(!response) return setDetailsData([])
                    setDetailsData(response)
                })
                break;
            case "Blasen-DP":
                setDetailsTitle(props.t("Blasen DP Report"));
                setStorageKey("blasenDPReport")
                getBlasenDPReportService(filters).then((response) => {
                    if(!response) return setDetailsData([])
                    setDetailsData(response)
                })
                break;
            case "Has":
                setDetailsTitle(props.t("Has Report"));
                setStorageKey("hasReport")
                getHasReportService(filters).then((response) => {
                    if(!response) return setDetailsData([])
                    setDetailsData(response)
                })
                break;
            case "Lassen":
                setDetailsTitle(props.t("Lassen Report"));
                setStorageKey("lassenReport")
                getLassenReportService(filters).then((response) => {
                    if(!response) return setDetailsData([])
                    setDetailsData(response)
                })
                break;
            case "Racket":
                setDetailsTitle(props.t("Racket Report"));
                setStorageKey("racketReport")
                getRacketReportService(filters).then((response) => {
                    if(!response) return setDetailsData([])
                    setDetailsData(response)
                })
                break;
        }
    }, [])

    return (<React.Fragment>
        <div className="page-content">
            <DataGridLoadPanel
                visible={detailsData === null}
                shadingColor="rgba(0, 0, 0, 0.5)"
                shading={false}
                height={100}
                width={140}
                message="Loading..."
                position={position}
            />
            <Container fluid className="dash">
                <h4>{detailsTitle}</h4>
                <Row className="mt-2">
                    <Col xl={12} className="mt-2">
                        <div className="shadow bg-white p-3 rounded-4 mt-2">
                            <DataGrid
                                allowColumnReordering={true}
                                showBorders={false}
                                className="mt-4"
                                dataSource={detailsData}
                                columnAutoWidth={true}
                                showColumnLines={false}
                                allowColumnResizing={true}
                                showRowLines={true}
                                keyExpr="id"
                                id="reports"
                                onExporting={onExporting}
                            >
                                <StateStoring enabled={true} type="localStorage" storageKey={storageKey}/>
                                <Export enabled={true} formats={exportFormats}/>
                                <LoadPanel enabled={false}/>
                                <Paging defaultPageSize={10}/>
                                <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true}
                                       allowedPageSizes={[5, 10, 20]}/>
                                <Grouping contextMenuEnabled={true}/>
                                <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                <HeaderFilter visible={true}/>
                                <Column dataField="allImage" caption={props.t("All Image")} cssClass="text-center vertical-middle" width={250}
                                        cellRender={(object) => {
                                            return (
                                                <div>
                                                    <ButtonGroup>
                                                        <RsButton color="primary" size="sm" outline onClick={() => {
                                                            setImageModal(true)
                                                            setSelectedImage(object.data)
                                                            setTitle("All Images")
                                                            if (allImage.length !== 0) return setAllImage([])
                                                            setAllImage(object.data.house_images)
                                                        }}>
                                                            All Images
                                                        </RsButton>
                                                    </ButtonGroup>
                                                </div>

                                            )
                                        }}></Column>
                                <Column dataField="id" caption={props.t("ID")} dataType="string"
                                        cssClass="bold text-center vertical-middle" visible={false}/>
                                <Column dataField="code" caption={props.t("House Code")} dataType="string"
                                        cssClass="bold text-center vertical-middle" visible={false}/>
                                <Column dataField="excel_code" caption={props.t("KLS ID")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="cast_code" caption={props.t("Cast Code")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="created_at" caption={props.t("Created At")} dataType="datetime"
                                        cssClass="vertical-middle" format={formatDate}/>
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_start_meter" caption={props.t("Start Meter")}
                                            cssClass="vertical-middle" dataType="number"/> : null}
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_end_meter" caption={props.t("End Meter")}
                                            cssClass="vertical-middle" dataType="number"/> : null}
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_cable_diff" caption={props.t("Cable Difference")}
                                            cssClass="vertical-middle" dataType="number" cellRender={(object) => {
                                        const value = Number(object.data.blasen_end_meter) - Number(object.data.blasen_start_meter)
                                        return (
                                            <div>
                                                {value}
                                            </div>
                                        )
                                    }}/> : null}
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_gf" caption={props.t("Phase Number")}
                                            cssClass="vertical-middle"/> : null}
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_cable_color" caption={props.t("Cable Color")}
                                            cssClass="vertical-middle"/> : null}
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_house_start_date" caption={props.t("Start Date")}
                                            cssClass="vertical-middle" dataType="date"/> : null}
                                {params.get("type") == "Blasen" ||params.get("type") == "Blasen-DP" ?
                                    <Column dataField="blasen_house_end_date" caption={props.t("End Date")}
                                            cssClass="vertical-middle" dataType="date"/> : null}
                                <Column dataField="notes" caption={props.t("Note")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="cast_id" caption={props.t("Cast ID")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="cast_lassen_completed_by_name"
                                        caption={`${props.t("Cast Lassen")} ${props.t("Completed By")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="cast_lassen_completed_date"
                                        caption={`${props.t("Cast Lassen")} ${props.t("Completed Date")}`}
                                        dataType="date" cssClass="vertical-middle" visible={false}/>
                                <Column dataField="cast_lassen_description"
                                        caption={`${props.t("Cast Lassen")} ${props.t("Description")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="dp_code" caption={props.t("DP Code")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="dp_id" caption={props.t("DP ID")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="dp_lassen_completed_by_name"
                                        caption={`${props.t("DP Lassen")} ${props.t("Completed By")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="dp_lassen_completed_date"
                                        caption={`${props.t("DP Lassen")} ${props.t("Completed Date")}`} dataType="date"
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="dp_lassen_description"
                                        caption={`${props.t("DP Lassen")} ${props.t("Description")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="dp_blasen_completed_by_name"
                                        caption={`${props.t("DP Blasen")} ${props.t("Completed By")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="dp_blasen_completed_date"
                                        caption={`${props.t("DP Blasen")} ${props.t("Completed Date")}`} dataType="date"
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="dp_blasen_description"
                                        caption={`${props.t("DP Blasen")} ${props.t("Description")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="street" caption={props.t("Street")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="house_no" caption={props.t("House No")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="house_no_extra" caption={props.t("House No Extra")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="customer_name" caption={props.t("Customer Name")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="customer_phone" caption={props.t("Customer Phone")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="customer_email" caption={props.t("Customer Email")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="house_type" caption={props.t("House Type")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="we" caption={props.t("WE")} cssClass="vertical-middle"/>
                                <Column dataField="ge" caption={props.t("GE")} cssClass="vertical-middle"/>
                                <Column dataField="city_name" caption={props.t("City")}
                                        cssClass="vertical-middle"/>
                                <Column dataField="description" caption={props.t("Description")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="lat" caption={props.t("Lat")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="long" caption={props.t("Long")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="status" caption={props.t("Project Status")}
                                        cssClass="text-center vertical-middle" visible={false}
                                        dataType="string" cellRender={object => {
                                    const value = object.data.status
                                    return (
                                        <span className="badge badge-pill font-size-12" style={{
                                            backgroundColor: badgeColorStatusValue(value).backgroundColor,
                                            color: badgeColorStatusValue(value).color
                                        }}>
                                                {value !== 0 ? props.t("Active") : props.t("Passive")}
                                            </span>
                                    )
                                }}/>
                                <Column dataField="house_completed_by_name" caption={"House " + props.t("Completed By")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="house_completed_date" caption={"House " + props.t("Completed Date")}
                                        cssClass="vertical-middle" dataType="date" visible={false}/>
                                <Column dataField="house_description" caption={"House " + props.t("Description")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="racket_completed_by_name"
                                        caption={"Racket " + props.t("Completed By")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="racket_completed_date"
                                        caption={"Racket " + props.t("Completed Date")} dataType="date"
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="racket_description" caption={"Racket " + props.t("Description")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="has_completed_by_name"
                                        caption={`${props.t("Has")} ${props.t("Completed By")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="has_completed_date"
                                        caption={`${props.t("Has")} ${props.t("Completed Date")}`} dataType="date"
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="has_description"
                                        caption={`${props.t("Has")} ${props.t("Description")}`}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="area_no" caption={props.t("Area No")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="bag_no" caption={props.t("Bag No")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="cable_length" caption={props.t("Cable Length")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="can_use_casing_pipe" caption={props.t("Can Use Casing Pipe")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="connection_type" caption={props.t("Connection Type")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="core_area" caption={props.t("Core Area")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="excel_description" caption={props.t("Excel Description")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="house_id" caption={props.t("House ID")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="is_there_a_power_point_near_the_modem"
                                        caption={props.t("Is There a Power Point Near The Modem")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="is_there_and_empty_space_under_the_house"
                                        caption={props.t("Is There and Empty Space Under The House")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="is_there_Any_other_infrastructure_in_the_garden"
                                        caption={props.t("Is There Any Other Infrastructure In The Garen")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="local_government" caption={props.t("Local Goverment")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="residential_use" caption={props.t("Residential Use")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="state" caption={props.t("State")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="supervisor_name" caption={props.t("Supervisor Name")}
                                        cssClass="vertical-middle" visible={false}/>
                                <Column dataField="units" caption={props.t("Units")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="user_id" caption={props.t("User ID")} cssClass="vertical-middle"
                                        visible={false}/>
                                <Column dataField="where_to_hang_the_modem" caption={props.t("Where To Hang The Modem")}
                                        cssClass="vertical-middle" visible={false}/>
                            </DataGrid>
                        </div>
                    </Col>
                </Row>
                {selectedImage && (
                    <ImageModal show={imageModal} toggle={() => {
                        setImageModal(false)
                        setSelectedImage(null)
                    }} selectedImage={selectedImage} allImage={allImage} title={title}/>
                )}
            </Container>
        </div>
    </React.Fragment>)
}

Details.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(Details)
)