import {TERMIN_URL} from "helpers/url_helper";

const { post,get,del,put } = require("helpers/api_helper");

export const getTerminScheduleService = async () => {
    const response = await get(TERMIN_URL);
    response.data = response.data?.map((item) => {
        return {
            terminNote: item.house_termin_note,
            customer_name: item.customer_name,
            customer_lastname: item.customer_lastname,
            customer_email: item.customer_email,
            customer_phone: item.customer_phone,
            customer_home_phone: item.customer_home_phone,
            startDate: item.house_termin_start_date,
            endDate: item.house_termin_end_date,
            user_id: item.user_id,
            title: item.user_name + " " + item.user_lastname,
            status: item.status,
            id: item.id,
            house_id: item.house_id,
        }
    });
    return response;
}

export const getTerminListService = async () => {
    return await get(TERMIN_URL)
    }
export const addTerminService = async (data) => {
    const body = {
        user_id: data.user_id,
        house_id:data.house_id,
        house_termin_start_date: data.startDate,
        house_termin_end_date: data.endDate,
        house_termin_note: data.terminNote,
        customer_name: data.customer_name,
        customer_lastname: data.customer_lastname,
        customer_email: data.customer_email,
        customer_phone: data.customer_phone,
        customer_home_phone: data.customer_home_phone,
        status: data.status,
    }
    return await post(TERMIN_URL, body);
}

export const updateTerminService = async (data) => {
    const body = {
        user_id: data.user_id,
        house_id: data.house_id,
        house_termin_start_date: data.startDate,
        house_termin_end_date: data.endDate,
        house_termin_note: data.terminNote,
        customer_name: data.customer_name,
        customer_lastname: data.customer_lastname,
        customer_email: data.customer_email,
        customer_phone: data.customer_phone,
        customer_home_phone: data.customer_home_phone,
        status: data.status,
    }
    return await put(`/termin/${data.id}`,body);
}

export const delTerminService = async (id) => {
    return await del(`/termin/${id}`);
}
