import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button as RsButton, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Column,
    ColumnChooser, Export,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {delWork, getChiefsWorkList, getWorkList} from "../../services/panel/userService";
import {formatDate} from "../../helpers/formatDate";
import WorkListModal from "./workListModal";
import {calculateTimeDifference} from "../../helpers/get_date";
import DeleteModal from "../../components/delModal";
import DataGridLoadPanel from "devextreme-react/load-panel";
import {OnExporting,OnExported} from "../../business/onExport";
const WorkList = (props) => {
    const position = {of: '#workList', at: 'center', my: 'center'};
    const [loading, setLoading] = useState(true)
    const [workList, setWorkList] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedWork, setSelectedWork] = useState(null);
    const [delModal, setDelModal] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [title, setTitle] = useState("");
    const [allImage, setAllImage] = useState([]);
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    useEffect(() => {
        if (params.get("userID")){
            getChiefsWorkList(params.get("userID")).then((response) => {
                if(response){
                    setLoading(false)
                    setWorkList(response.data)
                }
            })
        }else{
            getWorkList().then((response) => {
                if(response){
                    setLoading(false)
                    setWorkList(response.data)
                }
            })
        }
    }, [])

    const Submit = () => {
        if (params.get("userID")){
            getChiefsWorkList(params.get("userID")).then((response) => {
                console.log(response.data)
                setWorkList(response.data)
            })
        }else{
            getWorkList().then((response) => {
                console.log(response.data)
                setWorkList(response.data)
            })
        }
    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid className="dash">
                <DataGridLoadPanel
                    visible={loading}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    shading={false}
                    height={100}
                    width={140}
                    message="Loading..."
                    position={position}
                />
                <h4>{props.t("Work List")}</h4>
                <Row className="mt-2">
                    <Col xl={12} className="mt-2">
                        <div className="shadow bg-white p-3 rounded-4 mt-2">
                            <DataGrid
                                allowColumnReordering={true}
                                showBorders={false}
                                className="mt-4"
                                dataSource={workList}
                                columnAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                keyExpr="id"
                                id="workList"
                                onExporting={OnExporting}
                                onExported={OnExported}
                            >
                                <Export enabled={true} fileName={props.t("Work List")}/>
                                <StateStoring enabled={true} type="localStorage" storageKey="workLists" />
                                <LoadPanel enabled={false}/>
                                <Paging defaultPageSize={10}/>
                                <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true} allowedPageSizes={[5,10,20]}/>
                                <Grouping contextMenuEnabled={true}/>
                                <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                <HeaderFilter visible={true}/>
                                <Toolbar>
                                    <Item location="before"
                                          name="groupPanel"
                                    />
                                    <Item location="after"
                                          name="columnChooserButton"
                                    />
                                    <Item location="after"
                                          name="exportButton"
                                    />
                                </Toolbar>
                                <Column  dataField="Transactions" cssClass="vertical-middle text-center" width={250} cellRender={(object) => {
                                    return (
                                        <div>
                                            <RsButton className="btn btn-sm btn-danger bg-white border-0"
                                                    onClick={() => {
                                                        setSelectedWork(object.row.data);
                                                        setDelModal(true);
                                                    }}>
                                                <i className="fa fa-trash trash-icon"/>
                                            </RsButton>
                                                <RsButton color="primary" size="sm" outline onClick={() => {
                                                    setImageModal(true)
                                                    setSelectedImage(object.data.work_images)
                                                    setTitle("Company Images")
                                                    if (allImage.length !== 0) return setAllImage([])
                                                    setAllImage(object.data.work_images)
                                                }}>
                                                    All Images
                                                </RsButton>
                                        </div>

                                    )
                                }}></Column>
                                <Column dataField="id" caption={props.t("ID")} cssClass="vertical-middle" visible={false}/>
                                <Column dataField="company_name" caption={props.t("Company Name")} cssClass="vertical-middle"/>
                                <Column dataField="total_worker" caption={props.t("Total Worker")} cssClass="vertical-middle"/>
                                <Column dataField="total_meter" caption={props.t("Total Meter")} cssClass="vertical-middle"/>
                                <Column dataField="user_name" caption={props.t("User Name")} cssClass="vertical-middle"/>
                                <Column dataField="user_lastname" caption={props.t("User LastName")} cssClass="vertical-middle"/>
                                <Column dataField="created_at" caption={props.t("Created At")} dataType="datetime" format={formatDate}/>
                                <Column dataField="start_date" caption={props.t("Start Date")} dataType="datetime" format={formatDate}/>
                                <Column dataField="end_date" caption={props.t("End Date")} dataType="datetime" format={formatDate}/>
                                <Column dataField="total_work" caption={props.t("Total Work")}/>
                            </DataGrid>
                        </div>
                    </Col>
                </Row>
            </Container>
            {selectedImage && (
                <WorkListModal show={imageModal} toggle={()=> {
                    setImageModal(false)
                    setSelectedImage(null)
                }} selectedImage={selectedImage} allImage={allImage} title={title}/>
            )}
            {selectedWork && (
                <DeleteModal show={delModal} toggle={() => setDelModal(false)} onDelete={(data) => {
                    delWork(data.id).then((res) => {
                        setDelModal(false)
                        if (!res.status) return error(res.message)
                        notify()
                        Submit()
                    })
                }} selectedData={selectedWork} body={props.t("Do you want to delete this Work?")}/>
            )}
        </div>
    </React.Fragment>)
}

WorkList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(WorkList)
)