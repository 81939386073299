import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap";

//i18n
import {withTranslation} from "react-i18next";
// Redux
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

// users
import getUser from "helpers/get_user";


const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const [userName, setUserName] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    const _user = getUser();
    if (_user) {
      setUser(_user);
      setUserName(_user.name);
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="bx bxs-user-circle font-size-24 align-middle me-1 text-white" />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-white">{userName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/logout" className="hover-green">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
