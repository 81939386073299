import {GET_COMPANY_URL} from "helpers/url_helper";

const { post,get,del } = require("helpers/api_helper");

export const getCompanyListService = async () => {
    return await get(GET_COMPANY_URL);
}

export const postCompanyListService = async (data) => {

    return await post(GET_COMPANY_URL, data);
}

export const delCompanyListService = async (id) => {

    return await del(`/company/${id}`);
}