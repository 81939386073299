export const badgeColorStatus = (value) => {
    value = value === true ? "true" : "false"
    const BgColorsObject = {
        false: {"backgroundColor": "#F46A6A4C", "textColor": "#F45050FF"},
        true: {"backgroundColor": "#34C38F4C", "textColor": "#1AA874FF"},
    }
    let colorsType = {};
    Object.keys(BgColorsObject).forEach((key) => {
        if (value == key) {
            const badgeColor = BgColorsObject[key]
            colorsType = {backgroundColor: badgeColor.backgroundColor, color: badgeColor.textColor};
        }
    })
    return colorsType
}

export const badgeColorStatusValue = (value) => {
    const BgColorsObject = {
        0: {"backgroundColor": "#F46A6A4C", "textColor": "#F45050FF"},
        1: {"backgroundColor": "#34C38F4C", "textColor": "#1AA874FF"},
    }
    let colorsType = {};
    Object.keys(BgColorsObject).forEach((key) => {
        if (value == key) {
            const badgeColor = BgColorsObject[key]
            colorsType = {backgroundColor: badgeColor.backgroundColor, color: badgeColor.textColor};
        }
    })
    return colorsType
}