import React, {useEffect, useState} from "react"
import {Button, Col, Container, Form, FormGroup, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap"
import DataGrid, {
    FilterRow,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
    StateStoring
} from "devextreme-react/data-grid"
import "../../assets/scss/custom.scss"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {getCookie} from "../../helpers/cookie_helper";
import Dropzone from 'react-dropzone';
import axios from "axios";
import {useFormik} from "formik";
import * as XLSX from 'xlsx';
import upload from "../../assets/images/upload.png";
import * as Yup from "yup";
import Select from "react-select";
import {getCityService, getCompanyService, getCountryService} from "../../services/panel/projectService";
import {error, notify} from "../../components/toastify";
import AddCompany from "./addProjectsModal/addCompany";
import AddCity from "./addProjectsModal/addCity";
import AddCountry from "./addProjectsModal/addCountry";

const addProjects = (props) => {
    const [loadingModal, setLoadingModal] = useState(false)
    const [countryModal, setCountryModal] = useState(false)
    const [cityModal, setCityModal] = useState(false)
    const [companyModal, setCompanyModal] = useState(false)
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [company, setCompany] = useState([]);
    const [excelData, setExcelData] = useState([])

    const handleDrop = (acceptedFiles) => {
        formik.setFieldValue('file', acceptedFiles[0]);
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const json = XLSX.utils.sheet_to_json(worksheet);
            setExcelData(json);
        };
        reader.readAsArrayBuffer(file);
    };

    const formik = useFormik({
        initialValues: {
            file: "",
            country: "",
            city: "",
            company: "",
        },
        validationSchema: Yup.object({
            country: Yup.object().required(),
            city: Yup.object().required(),
            company: Yup.object().required(),
        }),
        onSubmit: async (values, {resetForm}) => {
            try {
                setLoadingModal(true);
                const formData = new FormData();
                const token = getCookie("access_token");

                formData.append('country_id', values.country.value);
                formData.append('city_id', values.city.value);
                formData.append('company_id', values.company.value);
                formData.append('xlsx', values.file);

                const response = await axios.post(`https://api.selecta-fiber.com/api/project/import_excel`, formData, {
                    headers: {
                        'x-access-token': token,
                    },
                    timeout: 0
                });

                resetForm();
                setLoadingModal(false);
                setExcelData([]);

                if (response && response.data) {
                    return notify(response.data.message, 7000);
                } else {
                    return error("Error");
                }
            } catch (ex) {
                console.error("onSubmit error", ex);
                setLoadingModal(false);
                return error(ex.message);
            }
        },
    });

    const handleCountryChange = (selectedOption) => {
        formik.setFieldValue('country', selectedOption);
        formik.setFieldValue('city', null);
        formik.setFieldValue('company', null);
        getCityService(selectedOption.value).then((response) => {
            const citiesOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name, country: item.country_id}
            })
            setCities(citiesOptions)
        })
    }

    const handleCityChange = (selectedOption) => {
        formik.setFieldValue('city', selectedOption);
        formik.setFieldValue('company', null);
        getCompanyService(selectedOption.country).then((response) => {
            const companyOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name}
            })
            setCompany(companyOptions)
        })
    }

    useEffect(() => {
        getCountryService().then((response) => {
            const countriesOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name}
            })
            setCountries(countriesOptions)
        })
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="dash">
                    <h4 className="mb-0 font-size-18">{props.t("Add Project")}</h4>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                    }} className="mt-2">
                        <Row className="shadow bg-white p-3 rounded-4 mt-2">
                            <Col xl={3}>
                                <FormGroup>
                                    <label htmlFor="country">
                                        {props.t("Country")}
                                        <Button className="ms-2 btn-green btn-sm" onClick={() => setCountryModal(true)}>
                                            <span className="font-size-12">+ {props.t("Add")}</span></Button>
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="country"
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={handleCountryChange}
                                        onBlur={formik.handleBlur}
                                        options={countries}
                                        value={formik.values.country}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="city">
                                        {props.t("City")}
                                        <Button className="ms-2 btn-green btn-sm" onClick={() => setCityModal(true)}>
                                            <span className="font-size-12">+ {props.t("Add")}</span></Button>
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="city"
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={handleCityChange}
                                        onBlur={formik.handleBlur}
                                        options={cities}
                                        value={formik.values.city}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="Company">
                                        {props.t("Company")}
                                        <Button className="ms-2 btn-green btn-sm" onClick={() => setCompanyModal(true)}>
                                            <span className="font-size-12">+ {props.t("Add")}</span></Button>
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="Company"
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={(e) => {
                                            formik.setFieldValue('company', e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={company}
                                        value={formik.values.company}
                                    />
                                </FormGroup>
                                <Dropzone onDrop={handleDrop}
                                          accept={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xls', '.xlsx']}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <div {...getRootProps()} className="dropzone dropzone-data dropzone-radius">
                                            <input {...getInputProps()} required={true}/>
                                            {isDragActive ? (
                                                <p className="dropzone-content">
                                                    <img className="img-size" src={upload} alt=""/>
                                                    <br/>{props.t("Drop File")}</p>
                                            ) : (
                                                <p className="dropzone-content">
                                                    <img src={upload} alt=""/>
                                                    <br/>{props.t("Drag and Drop")}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>

                            </Col>
                            <Col xl={9} className="mtgrid">
                                <div className="shadow p-3 rounded-4 mt-2">
                                    <DataGrid
                                        allowColumnReordering={true}
                                        className="mt-3 Data-grid-height"
                                        dataSource={excelData}
                                        columnAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        id="excelData"
                                    >
                                        <StateStoring enabled={true} type="localStorage" storageKey="excelData"/>
                                        <HeaderFilter visible={true}/>
                                        <FilterRow/>
                                        <Paging defaultPageSize={10}/>
                                        <Grouping contextMenuEnabled={true}/>
                                        <GroupPanel
                                            visible={true}
                                            emptyPanelText={props.t("Drag Here to Group")}
                                        />
                                        <Pager showInfo={true} showNavigationButtons={true}/>
                                        <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                        <HeaderFilter visible={true}/>

                                    </DataGrid>
                                    <Button
                                        className="mt-4 float-end btn-soft btn-info btn-cdata"
                                        size="sm"
                                        type="submit">
                                        <span className="cdata-text">{props.t("Save")}</span>
                                    </Button>{" "}
                                    <Button
                                        className="mt-4 float-end btn-soft btn-primary btn-cdata"
                                        size="sm"
                                        onClick={() => {
                                            setExcelData([])
                                            formik.resetForm()
                                        }}
                                    >
                                        <span className="cdata-text">{props.t("Delete")}</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <AddCountry show={countryModal} toggle={() => setCountryModal(false)}/>
                <AddCity show={cityModal} toggle={() => setCityModal(false)} countries={countries}/>
                <AddCompany show={companyModal} toggle={() => setCompanyModal(false)} countries={countries}/>
                <Modal isOpen={loadingModal} toggle={() => {
                    setLoadingModal(false)
                }} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <ModalHeader toggle={() => {
                        setLoadingModal(false)
                    }}><span className="float-center">{props.t("Excel is being saved...")}</span></ModalHeader>
                    <ModalBody>
                        <div>
                            <span className="font-size-18 me-2">{props.t("Excel is being saved...")}</span>
                            <Spinner animation="border" variant="success" className="ms-2 text-green" size="sm"/>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    )
}
addProjects.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(addProjects)
)
