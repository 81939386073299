import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Select from "react-select";
import {getAddressUserService} from "../../../services/panel/userService";
import {useFormik} from "formik";
import * as Yup from "yup";
import {error, notify} from "../../../components/toastify";
import {updateTerminService} from "../../../services/panel/terminService";

const EditTermin = (props) => {
    const {show, toggle, selectedTermin, onEdit, addressData} = props;
    const [userData, setUserData] = useState([]);
    const NoUser = [{value: "", label: "No User"}]
    const Status = [
        {value: "0", label: props.t("Waiting")},
        {value: "1", label: props.t("Completed")},
        {value: "-1", label: props.t("Canceled")}
    ]
    const editTerminData = useFormik({
        initialValues: {
            id: selectedTermin.id,
            house_id: selectedTermin.house_id,
            user_id: selectedTermin.user_id,
            startDate: selectedTermin.house_termin_start_date,
            endDate: selectedTermin.house_termin_end_date,
            customer_name: selectedTermin.customer_name,
            customer_lastname: selectedTermin.customer_lastname,
            customer_email: selectedTermin.customer_email,
            customer_phone: selectedTermin.customer_phone,
            customer_home_phone: selectedTermin.customer_home_phone,
            terminNote: selectedTermin.house_termin_note,
            status: selectedTermin.status,
        },
        validationSchema: Yup.object({
            house_id: Yup.string().required(props.t("")),
            user_id: Yup.string().required(props.t("")),
        }),
        onSubmit: (values, {resetForm}) => {
            updateTerminService(values, selectedTermin.id).then((res) => {
                toggle()
                resetForm()
                if (!res.status) return error(res.message)
                notify()
                onEdit(true)
            })
        }
    })
    useEffect(() => {
        getAddressUserService(selectedTermin.house_id).then((response) => {
            const UserOptions = response.data?.map((item) => {
                return {
                    value: item.id,
                    label: item.name + " " + item.lastname + " (Role: " + item.role + ")"
                }
            })
            setUserData(UserOptions)
        })
    }, []);

    return (
        <React.Fragment>
            <Modal isOpen={show} toggle={toggle} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form className="form-horizontal" onSubmit={(e) => {
                    e.preventDefault();
                    editTerminData.handleSubmit();
                }}>
                    <ModalHeader toggle={toggle} className=""><span className="float-center">{props.t("Edit Termin")}</span></ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label htmlFor="house_id">{props.t("Address")}</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="house_id"
                                isSearchable={true}
                                isClearable={false}
                                defaultValue={{value: selectedTermin.house_id, label: selectedTermin.address}}
                                onChange={(e) => {
                                    editTerminData.setFieldValue('house_id', e.value);
                                    editTerminData.setFieldValue('user_id', "")
                                    getAddressUserService(e.value).then((response) => {
                                        if (!response.data) return setUserData(NoUser)
                                        const UserOptions = response.data?.map((item) => {
                                            return {
                                                value: item.id,
                                                label: item.name + " " + item.lastname + " (Role: " + item.role + ")"
                                            }
                                        })
                                        setUserData(UserOptions)
                                    })
                                }}
                                onBlur={editTerminData.handleBlur}
                                options={addressData}
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="user_id">{props.t("User")}</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="user_id"
                                isSearchable={true}
                                isClearable={true}
                                defaultValue={{
                                    value: selectedTermin.user_id,
                                    label: selectedTermin.user_name + " " + selectedTermin.user_lastname
                                }}
                                onChange={(e) => {
                                    editTerminData.setFieldValue('user_id', e.value);
                                }}
                                onBlur={editTerminData.handleBlur}
                                options={userData}
                                required={true}
                            >
                            </Select>
                        </FormGroup>
                        <Row className="mt-1">
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="house_termin_start_date">{props.t("Start Date")}</label>
                                    <Input
                                        name="house_termin_start_date"
                                        className="form-control"
                                        type="datetime-local"
                                        step="1"
                                        onChange={(e) => {
                                            editTerminData.setFieldValue('startDate', e.target.value);
                                        }}
                                        value={editTerminData.values.startDate}
                                        onBlur={editTerminData.handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="house_termin_end_date">{props.t("End Date")}</label>
                                    <Input
                                        name="house_termin_end_date"
                                        className="form-control"
                                        type="datetime-local"
                                        step="1"
                                        onChange={(e) => {
                                            editTerminData.setFieldValue('endDate', e.target.value);
                                        }}
                                        onBlur={editTerminData.handleBlur}
                                        value={editTerminData.values.endDate}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="customer_name">{props.t("Customer Name")}</label>
                                    <Input
                                        name="customer_name"
                                        className="form-control"
                                        type="text"
                                        maxLength={50}
                                        onChange={editTerminData.handleChange}
                                        onBlur={editTerminData.handleBlur}
                                        value={editTerminData.values.customer_name || ""}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="customer_lastname">{props.t("Customer LastName")}</label>
                                    <Input
                                        name="customer_lastname"
                                        className="form-control"
                                        type="text"
                                        maxLength={50}
                                        onChange={editTerminData.handleChange}
                                        onBlur={editTerminData.handleBlur}
                                        value={editTerminData.values.customer_lastname || ""}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label htmlFor="customer_email">{props.t("Customer Email")}</label>
                            <Input
                                name="customer_email"
                                className="form-control"
                                type="email"
                                max={50}
                                onChange={editTerminData.handleChange}
                                onBlur={editTerminData.handleBlur}
                                value={editTerminData.values.customer_email || ""}
                            />
                        </FormGroup>
                        <Row className="mt-1">
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="customer_phone">{props.t("Customer Phone")}</label>
                                    <Input
                                        name="customer_phone"
                                        className="form-control"
                                        type="tel"
                                        maxLength={10}
                                        onChange={editTerminData.handleChange}
                                        onBlur={editTerminData.handleBlur}
                                        value={editTerminData.values.customer_phone || ""}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="customer_home_phone">{props.t("Customer Home Phone")}</label>
                                    <Input
                                        name="customer_home_phone"
                                        className="form-control"
                                        type="tel"
                                        maxLength={10}
                                        onChange={editTerminData.handleChange}
                                        onBlur={editTerminData.handleBlur}
                                        value={editTerminData.values.customer_home_phone || ""}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label htmlFor="status">{props.t("Status")}</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="status"
                                isSearchable={true}
                                isClearable={true}
                                onChange={(e) => {
                                    editTerminData.setFieldValue('status', e.value);
                                }}
                                onBlur={editTerminData.handleBlur}
                                options={Status}
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="terminNote">{props.t("Termin Note")}</label>
                            <Input
                                name="terminNote"
                                className="form-control"
                                type="textarea"
                                maxLength={50}
                                onChange={editTerminData.handleChange}
                                onBlur={editTerminData.handleBlur}
                                value={editTerminData.values.terminNote || ""}
                                style={{height: 50, resize: "none"}}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="form-footer">
                        <Button
                            block
                            color="success"
                            className="btn-green"
                            type="submit"
                        >
                            {props.t("Add Termin")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

EditTermin.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(EditTermin)
)