import {GET_PROJECT_LIST, SET_EXCEL_DATA, SET_EXCEL_FILE} from "./actionTypes"

export const setExcelData = data =>{
    return{
        type: SET_EXCEL_DATA,
        payload: data,
    }
}


export const setExcelFile = data =>{
    return{
        type: SET_EXCEL_FILE,
        payload: data,
    }
}

export const getProjectData = data =>{
    return{
        type: GET_PROJECT_LIST,
        payload: data,
    }
}