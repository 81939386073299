import React from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {useFormik} from "formik";
import {editUserService} from "../../services/panel/userService";
import {error, notify} from "../../components/toastify"


const EditUser = (props) => {
    const {show, toggle, isEditing, selectedUser, userRoleData} = props;
    const EditUser = useFormik({
        enableReinitialize: true,
        initialValues: {
            editRole: selectedUser.role,
            editLastName: selectedUser.lastname,
            editName: selectedUser.name
        },
        onSubmit: (values, {resetForm}) => {
            editUserService(values, selectedUser.id).then((res) => {
                resetForm()
                if (!res.status) return error(res.message)
                toggle()
                notify()
                isEditing(true)
            })
        }
    });
    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Form className="form-horizontal"
                  onSubmit={(e) => {
                      e.preventDefault();
                      EditUser.handleSubmit();
                      // return false;
                  }}
            >
                <ModalHeader toggle={toggle}><span className="float-center">{props.t("Edit User")}</span></ModalHeader>
                <ModalBody>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("Name")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-user"/>
                            </InputGroupText>
                            <Input
                                name="editName"
                                className="form-control border-0"
                                placeholder={props.t("Please Name")}
                                type="text"
                                maxLength={50}
                                onChange={EditUser.handleChange}
                                onBlur={EditUser.handleBlur}
                                value={EditUser.values.editName || ""}
                                required={true}
                                invalid={
                                    !!(EditUser.touched.editName && EditUser.errors.editName)
                                }/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("LastName")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-user"/>
                            </InputGroupText>
                            <Input
                                name="editLastName"
                                className="form-control border-0"
                                placeholder={props.t("Please LastName")}
                                type="text"
                                maxLength={50}
                                onChange={EditUser.handleChange}
                                onBlur={EditUser.handleBlur}
                                value={EditUser.values.editLastName || ""}
                                required={true}
                                invalid={
                                    !!(EditUser.touched.editLastName && EditUser.errors.editLastName)
                                }/>

                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="border-bottom-green">
                        <Label className="form-label text-black">{props.t("User Role")}</Label>
                        <InputGroup className="border-0">
                            <InputGroupText className="bg-white border-0">
                                <i className="fa fa-user-edit"/>
                            </InputGroupText>
                            <Input
                                name="editRole"
                                className="form-control border-0"
                                type="select"
                                onChange={EditUser.handleChange}
                                onBlur={EditUser.handleBlur}
                                value={EditUser.values.editRole || ""}
                                required={true}
                                invalid={
                                    !!(EditUser.touched.editRole && EditUser.errors.editRole)
                                }>
                                <option value="SelectRole">{props.t("Select Role")}</option>
                                {userRoleData !== null ?
                                    userRoleData.map((item, index) => {
                                        return (<option key={index} value={item.name}>{item.name}</option>)
                                    })
                                    :
                                    ""
                                }
                            </Input>
                        </InputGroup>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="form-footer">
                    <Button
                        block
                        color="success"
                        className="btn-green"
                        type="submit"
                    >
                        {props.t("Edit User")}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    </React.Fragment>)
}

EditUser.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(EditUser)
)