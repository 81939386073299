import {call, put, takeEvery} from "redux-saga/effects"

// Login Redux States
import {apiFail, apiSuccess,} from "../dashboard/actions"
import {getUserListService} from "../../services/panel/userService";
import {GET_USER_LIST_DATA} from "./actionTypes";

function* getUserListData(data) {
    try {
        const getUserListServiceResponse = yield call(getUserListService,data);
        yield put(apiSuccess(GET_USER_LIST_DATA, getUserListServiceResponse));
    } catch (error) {
        yield put(apiFail(error))
    }
}

function* userSaga() {
    yield takeEvery(GET_USER_LIST_DATA, getUserListData);
}

export default userSaga;
