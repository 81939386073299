import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Breadcrumb, BreadcrumbItem, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Button,
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {getDPListService} from "../../services/panel/projectService";
import {badgeColorStatusValue} from "../../constants/badgecolor";
import DataGridLoadPanel from "devextreme-react/load-panel";


const projectDPList= (props) => {
    const position = {of: '#dpList', at: 'center', my: 'center'};
    const [projectListData, setProjectListData] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useHistory();
    let location = useLocation();
    let params = new URLSearchParams(location.search);

    const dpList = (row) => {
        navigate.push({
            pathname: "/projects/house",
            search: `?projectID=${params.get("projectID")}&castID=${params.get("castID")}&dpID=${row.id}`,
        })
    }

    useEffect(() => {
        getDPListService(params.get("projectID"),params.get("castID")).then((response)=>{
            if(response){
                setProjectListData(response.data)
                setLoading(false)
            }
        })
    }, [])

    return (<React.Fragment>
        <div className="page-content">
            <DataGridLoadPanel
                visible={loading}
                shadingColor="rgba(0, 0, 0, 0.5)"
                shading={false}
                height={100}
                width={140}
                message="Loading..."
                position={position}
            />
            <Container fluid className="dash">
                <Breadcrumb className="font-size-18">
                    <BreadcrumbItem>
                        <Link to={"/projects"}>{props.t("Project List")}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={{
                            pathname: "cast",
                            search: `?projectID=${params.get("projectID")}`,
                        }}>{props.t("Cast")}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {props.t("DP")}
                    </BreadcrumbItem>
                </Breadcrumb>
                <Row className="mt-2">
                    <Col xl={12} className="mt-2">
                        <div className="shadow bg-white p-3 rounded-4 mt-2">
                            <DataGrid
                                allowColumnReordering={true}
                                showBorders={false}
                                className="mt-4"
                                dataSource={projectListData}
                                allow
                                columnAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                id="dpList"
                            >
                                <StateStoring enabled={true} type="localStorage" storageKey="dpList" />
                                <LoadPanel enabled={false}/>
                                <Paging defaultPageSize={10}/>
                                <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true} allowedPageSizes={[5,10,20]}/>
                                <Grouping contextMenuEnabled={true}/>
                                <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                <HeaderFilter visible={true}/>
                                <Toolbar>
                                    <Item location="before"
                                          name="groupPanel"
                                    />
                                    <Item location="after"
                                          name="columnChooserButton"
                                    />
                                </Toolbar>
                                <Column dataField="" caption={props.t("")} width={150} type="buttons">
                                    <Button
                                        cssClass="btn btn-orange btn-sm font-size-12"
                                        text={props.t("HOUSE")}
                                        onClick={(object) => {
                                            dpList(object.row.data)
                                        }}>
                                    </Button>
                                </Column>
                                <Column dataField="id" caption={props.t("ID")} dataType="string" cssClass="bold text-center vertical-middle" visible={false}></Column>
                                <Column dataField="code" caption={props.t("DP Code")} dataType="string" cssClass="bold text-center vertical-middle"></Column>
                                <Column dataField="description" caption={props.t("Description")} cssClass="vertical-middle"></Column>
                                <Column dataField="status" caption={props.t("Project Status")} cssClass="text-center vertical-middle"
                                        dataType="string" visible={false} cellRender={object => {
                                    const value = object.data.status
                                    return (
                                        <span className="badge badge-pill font-size-12" style={{ backgroundColor: badgeColorStatusValue(value).backgroundColor, color: badgeColorStatusValue(value).color}}>
                                                {value !== 0 ? props.t("Active") : props.t("Passive")}
                                            </span>
                                    )}}>
                                </Column>
                                <Column dataField="completed_by" caption={props.t("Completed By")} cssClass="vertical-middle"></Column>
                                <Column dataField="blasen_completed_by_name" caption={"Blassen "+props.t("Completed By")} cssClass="vertical-middle" visible={false}></Column>
                                <Column dataField="blasen_completed_date" caption={"Blassen "+props.t("Completed Date")} cssClass="vertical-middle" dataType="date" visible={false}></Column>
                                <Column dataField="blasen_description" caption={"Blassen "+props.t("Description")} cssClass="vertical-middle" visible={false}></Column>
                                <Column dataField="lassen_completed_by_name" caption={"Lassen "+props.t("Completed By")} cssClass="vertical-middle" visible={false}></Column>
                                <Column dataField="lassen_completed_date" caption={"Lassen "+props.t("Completed Date")} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                <Column dataField="lassen_description" caption={"Lassen "+props.t("Description")} cssClass="vertical-middle" visible={false}></Column>
                                <Column dataField="created_at" caption={props.t("Created At")} dataType="date" cssClass="vertical-middle"></Column>
                                <Column dataField="updated_at" caption={props.t("Updated At")} dataType="date" cssClass="vertical-middle"></Column>
                            </DataGrid>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    </React.Fragment>)
}

projectDPList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(projectDPList)
)