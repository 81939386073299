import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Badge, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Button,
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {getAddressService} from "../../../services/panel/userService";
import AddTermin from "./addTermin";
import EditTermin from "./editTermin";
import {formatDate} from "../../../helpers/formatDate";
import {delTerminService, getTerminListService} from "../../../services/panel/terminService";
import DeleteModal from "../../../components/delModal";
import {error, notify} from "../../../components/toastify";
import DataGridLoadPanel from "devextreme-react/load-panel";


const AllTermin = (props) => {
    const position = {of: '#datagrid', at: 'center', my: 'center'};
    const [addressData, setAddressData] = useState(null)
    const [terminData, setTerminData] = useState(null)
    const [selectedTermin, setSelectedTermin] = useState(null)
    const [editModal, setEditModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [delModal, setDelModal] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getAddressService().then((response) => {
            const AddressOptions = response.data?.map((item) => {
                return {value: item.id, label: item.address}
            })
            setAddressData(AddressOptions)
        })
        getTerminListService().then((response) => {
            if(response){
                setTerminData(response.data)
                setLoading(false)
            }
        })
    }, [setTerminData])

    const Submit = () => {
        getTerminListService().then((response) => {
            setTerminData(response.data)
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="dash">
                    <DataGridLoadPanel
                        visible={loading}
                        shadingColor="rgba(0, 0, 0, 0.5)"
                        shading={false}
                        height={100}
                        width={140}
                        message="Loading..."
                        position={position}
                    />
                    <h4>{props.t("Termin List")}</h4>
                    <Row className="mt-2">
                        <Col xl={12} className="mt-2">
                            <div className="shadow bg-white p-3 rounded-4 mt-2">
                                <DataGrid
                                    allowColumnReordering={true}
                                    showBorders={false}
                                    className="mt-4"
                                    id="terminGrid"
                                    dataSource={terminData}
                                    columnAutoWidth={true}
                                    showColumnLines={false}
                                    showRowLines={true}
                                >
                                    <StateStoring enabled={true} type="localStorage" storageKey="terminGrid"/>
                                    <LoadPanel enabled={false}/>
                                    <Paging defaultPageSize={10}/>
                                    <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true}
                                           allowedPageSizes={[5, 10, 20]}/>
                                    <Grouping contextMenuEnabled={true}/>
                                    <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                    <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                    <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                    <HeaderFilter visible={true}/>
                                    <Toolbar>
                                        <Item location="before"
                                              name="groupPanel"
                                        />
                                        <Item location="after"
                                              name="columnChooserButton"
                                        />
                                        <Item location="after">
                                            <div className="btn btn-green btn btn-sm btn-semi-bold" onClick={() => {
                                                setAddModal(!addModal)
                                            }}><i
                                                className="fa fa-plus font-size-16 me-2"/>{props.t("Add Termin")}
                                            </div>
                                        </Item>
                                    </Toolbar>
                                    <Column dataField="Transaction" caption={props.t("Transaction")} width={150}
                                            type="buttons">
                                        <Button
                                            cssClass="fa fa-pen edit-icon"
                                            icon="edit"
                                            onClick={(object) => {
                                                setSelectedTermin(object.row.data)
                                                setEditModal(true)
                                            }}>
                                        </Button>
                                        <Button
                                            cssClass="fa fa-trash trash-icon"
                                            onClick={(object) => {
                                                setSelectedTermin(object.row.data)
                                                setDelModal(true)
                                            }}>
                                        </Button>
                                    </Column>
                                    <Column dataField="status" caption={props.t("Status")} alignment="center"
                                            cssClass="bold" cellRender={(object) => {
                                        if (object.data.status === 0) return <Badge
                                            color="warning">{props.t("Pending")}</Badge>
                                        if (object.data.status === 1) return <Badge
                                            color="success">{props.t("Completed")}</Badge>
                                        if (object.data.status === -1) return <Badge
                                            color="danger">{props.t("Canceled")}</Badge>
                                    }}/>
                                    <Column dataField="id" caption={props.t("ID")} width={100} alignment="center"
                                            cssClass="bold" visible={false}/>
                                    <Column dataField="house_id" visible={false} caption={props.t("House ID")}
                                            alignment="center" cssClass="bold"/>
                                    <Column dataField="user_id" visible={false} caption={props.t("User ID")}
                                            alignment="center" cssClass="bold"/>
                                    <Column dataField="user_name" caption={props.t("User Name")}/>
                                    <Column dataField="user_lastname" caption={props.t("User LastName")}/>
                                    <Column dataField="house_code" caption={props.t("House Code")} alignment="center"/>
                                    <Column dataField="post_code" caption={props.t("Post Code")} alignment="center"/>
                                    <Column dataField="address" caption={props.t("House Address")}/>
                                    <Column dataField="house_termin_note" caption={props.t("House Note")}/>
                                    <Column dataField="customer_name" caption={props.t("Customer Name")}/>
                                    <Column dataField="customer_lastname" caption={props.t("Customer LastName")}/>
                                    <Column dataField="customer_email" caption={props.t("Customer Email")}/>
                                    <Column dataField="customer_home_phone" caption={props.t("Customer Home Phone")}/>
                                    <Column dataField="customer_phone" caption={props.t("Customer Phone")}/>
                                    <Column dataField="created_at" visible={false} caption={props.t("Created_at")}
                                            cellRender={(object) => {
                                                return <span>{formatDate(object.data.created_at)}</span>
                                            }}/>
                                    <Column dataField="house_termin_start_date" visible={false}
                                            caption={props.t("Termin Start Date")} cellRender={(object) => {
                                        return <span>{formatDate(object.data.house_termin_start_date)}</span>
                                    }}/>
                                    <Column dataField="house_termin_end_date" visible={false}
                                            caption={props.t("Termin End Date")} cellRender={(object) => {
                                        return <span>{formatDate(object.data.house_termin_end_date)}</span>
                                    }}/>
                                    <Column dataField="house_termin_completed_date" visible={false}
                                            caption={props.t("Termin Complete Date")} cellRender={(object) => {
                                        return <span>{formatDate(object.data.house_termin_completed_date)}</span>
                                    }}/>
                                    <Column dataField="updated_at" visible={false} caption={props.t("Update At")}
                                            cellRender={(object) => {
                                                return <span>{formatDate(object.data.updated_at)}</span>
                                            }}/>

                                </DataGrid>
                            </div>
                        </Col>
                    </Row>
                    <AddTermin show={addModal} toggle={() => setAddModal(false)} onAdding={() => Submit()}
                               addressData={addressData}/>
                    <DeleteModal show={delModal} toggle={() => setDelModal(false)} onDelete={(data) => {
                        delTerminService(data.id).then((res) => {
                            setDelModal(false)
                            if (!res.status) return error(res.message)
                            notify()
                            Submit()
                        })
                    }} selectedData={selectedTermin} body={props.t("Do you want to delete this Termin?")}/>
                    {selectedTermin && (
                        <EditTermin show={editModal} toggle={() => {
                            {
                                setEditModal(false)
                                setSelectedTermin(null)
                            }
                        }} onEdit={(value) => Submit(value)} selectedTermin={selectedTermin}
                                    addressData={addressData}/>)}
                </Container>
            </div>
        </React.Fragment>)
}

AllTermin.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AllTermin)
)