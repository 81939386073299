import React, {useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem, Modal, ModalBody, ModalHeader} from "reactstrap";
import notFoundImage from "../../assets/images/not-found.png";

const WorkListModal = (props) => {
    const {show, toggle,selectedImage,title } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === selectedImage.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? selectedImage.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const Slide = selectedImage.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item}
            >
                <img
                    className="h-100 w-100"
                    src={"https://file-bucket-selecta.s3.eu-central-1.amazonaws.com/" + item}
                    onError={(event) => {
                        event.target.src = notFoundImage
                    }}
                    border="0" style={{objectFit: "cover"}} alt="selecta"/>
            </CarouselItem>
        );
    });
    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="lg"
               aria-labelledby="contained-modal-title-vcenter" centered>
            <ModalHeader toggle={toggle}><span
                className="float-center">{props.t(title)}</span></ModalHeader>
            <ModalBody>
                {selectedImage.length === 0 ? <img className="h-100 w-100" src={notFoundImage} border="0" style={{objectFit: "cover"}} alt="selecta"/>
                    :
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        className="mt-3"
                    >
                        <CarouselIndicators
                            items={selectedImage}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                        />
                        {selectedImage ? Slide : ""}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            className="text-primary"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            className="text-green"
                            onClickHandler={next}
                        />
                    </Carousel>
                }
            </ModalBody>
        </Modal>
    </React.Fragment>)
}

WorkListModal.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(WorkListModal)
)