import usFlag from "../assets/images/flags/us.jpg"
import germany from "../assets/images/flags/germany.jpg";
import trFlag from "../assets/images/flags/turkish.png";
import nldFlag from "../assets/images/flags/holland.png";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  gr: {
    label: "Deutsch",
    flag: germany,
  },
  tr: {
    label: "Türkçe",
    flag: trFlag,
  },
  nld: {
    label: "Nederlands",
    flag: nldFlag,
  },
}
export default languages
