import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {Link, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button as RsButton,
    ButtonGroup,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import DataGrid, {
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {getHouseListService, getPDFService} from "../../../services/panel/projectService";
import {badgeColorStatusValue} from "../../../constants/badgecolor";
import ImageModal from "./imageModal";
import DataGridLoadPanel from "devextreme-react/load-panel"


const projectHouseList = (props) => {
    const position = {of: '#houseList', at: 'center', my: 'center'};
    const [imageModal, setImageModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState("");
    const [allImage, setAllImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [projectListData, setProjectListData] = useState(null)
    let location = useLocation();
    let params = new URLSearchParams(location.search);

    useEffect(() => {
        getHouseListService(params.get("projectID"), params.get("castID"), params.get("dpID")).then((response) => {
            if(response){
                setProjectListData(response.data)
                setLoading(false)
            }
        })
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <DataGridLoadPanel
                    visible={loading}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    shading={false}
                    height={100}
                    width={140}
                    message="Loading..."
                    position={position}
                />
                <Container fluid className="dash">
                    <Breadcrumb className="font-size-18">
                        <BreadcrumbItem>
                            <Link to={"/projects"}>{props.t("Project List")}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to={{
                                pathname: "cast",
                                search: `?projectID=${params.get("projectID")}`,
                            }}>{props.t("Cast")}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to={{
                                pathname: "dp",
                                search: `?projectID=${params.get("projectID")}&castID=${params.get("castID")}`,
                            }}>{props.t("DP")}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{props.t("House")}</BreadcrumbItem>
                    </Breadcrumb>
                    <Row className="mt-2">
                        <Col xl={12} className="mt-2">
                            <div className="shadow bg-white p-3 rounded-4 mt-2">
                                <DataGrid
                                    showBorders={false}
                                    className="mt-4"
                                    dataSource={projectListData}
                                    columnAutoWidth={true}
                                    showColumnLines={false}
                                    allowColumnReordering={true}
                                    showRowLines={true}
                                    id="houseList"
                                >
                                    <StateStoring enabled={true} type="localStorage" storageKey="HouseList" />
                                    <LoadPanel enabled={false}/>
                                    <Paging defaultPageSize={10}/>
                                    <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true} allowedPageSizes={[5,10,20]}/>
                                    <Grouping contextMenuEnabled={true}/>
                                    <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                    <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                    <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                    <HeaderFilter visible={true}/>
                                    <Toolbar>
                                        <Item location="before" name="groupPanel"/>
                                        <Item location="after" name="columnChooserButton"/>
                                    </Toolbar>
                                    <Column  dataField="allImage" cssClass="vertical-middle text-center" width={250} cellRender={(object) => {
                                        return (
                                            <div>
                                                <ButtonGroup>
                                                    <RsButton color="success" size="sm" outline onClick={()=>{
                                                        setLoadingModal(true)
                                                        getPDFService(object.data.id).then((response) => {
                                                            window.open(response, '_blank');
                                                            setLoadingModal(false);
                                                        });
                                                    }}>
                                                        Reports
                                                    </RsButton>
                                                    <RsButton color="primary" size="sm" outline onClick={() => {
                                                        setImageModal(true)
                                                        setSelectedImage(object.data)
                                                        setTitle("All Images")
                                                        if (allImage.length !== 0) return setAllImage([])
                                                        setAllImage(object.data.house_images)
                                                    }}>
                                                        All Images
                                                    </RsButton>
                                                </ButtonGroup>
                                            </div>

                                        )
                                    }}></Column>
                                    <Column dataField="id" caption={props.t("ID")} dataType="string" cssClass="bold text-center vertical-middle" visible={false}></Column>
                                    <Column dataField="code" caption={props.t("House Code")} dataType="string" cssClass="bold text-center vertical-middle" visible={false}></Column>
                                    <Column dataField="excel_code" caption={props.t("KLS ID")} cssClass="vertical-middle"></Column>
                                    <Column dataField="cast_code" caption={props.t("Cast Code")} cssClass="vertical-middle"></Column>
                                    <Column dataField="cast_id" caption={props.t("Cast ID")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="cast_lassen_completed_by_name" caption={`${props.t("Cast Lassen")} ${props.t("Completed By")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="cast_lassen_completed_date" caption={`${props.t("Cast Lassen")} ${props.t("Completed Date")}`} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="cast_lassen_description" caption={`${props.t("Cast Lassen")} ${props.t("Description")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_code" caption={props.t("DP Code")} cssClass="vertical-middle"></Column>
                                    <Column dataField="dp_id" caption={props.t("DP ID")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_lassen_completed_by_name" caption={`${props.t("DP Lassen")} ${props.t("Completed By")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_lassen_completed_date" caption={`${props.t("DP Lassen")} ${props.t("Completed Date")}`} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_lassen_description" caption={`${props.t("DP Lassen")} ${props.t("Description")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_blasen_completed_by_name" caption={`${props.t("DP Blasen")} ${props.t("Completed By")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_blasen_completed_date" caption={`${props.t("DP Blasen")} ${props.t("Completed Date")}`} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="dp_blasen_description" caption={`${props.t("DP Blasen")} ${props.t("Description")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="street" caption={props.t("Street")} cssClass="vertical-middle"></Column>
                                    <Column dataField="house_no" caption={props.t("House No")} cssClass="vertical-middle"></Column>
                                    <Column dataField="house_no_extra" caption={props.t("House No Extra")} cssClass="vertical-middle"></Column>
                                    <Column dataField="customer_name" caption={props.t("Customer Name")} cssClass="vertical-middle"></Column>
                                    <Column dataField="customer_phone" caption={props.t("Customer Phone")} cssClass="vertical-middle"></Column>
                                    <Column dataField="customer_email" caption={props.t("Customer Email")} cssClass="vertical-middle"></Column>
                                    <Column dataField="house_type" caption={props.t("House Type")} cssClass="vertical-middle"></Column>
                                    <Column dataField="we" caption={props.t("WE")} cssClass="vertical-middle"></Column>
                                    <Column dataField="ge" caption={props.t("GE")} cssClass="vertical-middle"></Column>
                                    <Column dataField="city_name" caption={props.t("City")} cssClass="vertical-middle" ></Column>
                                    <Column dataField="description" caption={props.t("Description")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="lat" caption={props.t("Lat")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="long" caption={props.t("Long")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="status" caption={props.t("Project Status")}
                                            cssClass="text-center vertical-middle" visible={false}
                                            dataType="string" cellRender={object => {
                                        const value = object.data.status
                                        return (
                                            <span className="badge badge-pill font-size-12" style={{
                                                backgroundColor: badgeColorStatusValue(value).backgroundColor,
                                                color: badgeColorStatusValue(value).color
                                            }}>
                                                {value !== 0 ? props.t("Active") : props.t("Passive")}
                                            </span>
                                        )
                                    }}>
                                    </Column>
                                    <Column dataField="house_completed_by_name" caption={"House " + props.t("Completed By")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="house_completed_date" caption={"House " + props.t("Completed Date")} cssClass="vertical-middle" dataType="date" visible={false}></Column>
                                    <Column dataField="house_description" caption={"House " + props.t("Description")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="racket_completed_by_name" caption={"Racket " + props.t("Completed By")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="racket_completed_date" caption={"Racket " + props.t("Completed Date")} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="racket_description" caption={"Racket " + props.t("Description")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="has_completed_by_name" caption={`${props.t("Has")} ${props.t("Completed By")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="has_completed_date" caption={`${props.t("Has")} ${props.t("Completed Date")}`} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="has_description" caption={`${props.t("Has")} ${props.t("Description")}`} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="created_at" caption={props.t("Created At")} dataType="date" cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="area_no" caption={props.t("Area No")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="bag_no" caption={props.t("Bag No")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="cable_length" caption={props.t("Cable Length")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="can_use_casing_pipe" caption={props.t("Can Use Casing Pipe")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="code" caption={props.t("Code")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="connection_type" caption={props.t("Connection Type")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="core_area" caption={props.t("Core Area")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="excel_description" caption={props.t("Excel Description")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="house_id" caption={props.t("House ID")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="is_there_a_power_point_near_the_modem" caption={props.t("Is There a Power Point Near The Modem")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="is_there_and_empty_space_under_the_house" caption={props.t("Is There and Empty Space Under The House")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="is_there_Any_other_infrastructure_in_the_garden" caption={props.t("Is There Any Other Infrastructure In The Garen")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="local_government" caption={props.t("Local Goverment")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="residential_use" caption={props.t("Residential Use")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="state" caption={props.t("State")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="supervisor_name" caption={props.t("Supervisor Name")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="units" caption={props.t("Units")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="user_id" caption={props.t("User ID")} cssClass="vertical-middle" visible={false}></Column>
                                    <Column dataField="where_to_hang_the_modem" caption={props.t("Where To Hang The Modem")} cssClass="vertical-middle" visible={false}></Column>
                                </DataGrid>
                            </div>
                        </Col>
                    </Row>
                    {selectedImage && (
                        <ImageModal show={imageModal} toggle={()=> {
                            setImageModal(false)
                            setSelectedImage(null)
                        }} selectedImage={selectedImage} allImage={allImage} title={title}/>
                    )}
                </Container>
                <Modal isOpen={loadingModal} toggle={() => {setLoadingModal(false)}} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <ModalHeader toggle={() => {setLoadingModal(false)}}><span className="float-center">{props.t("PDF is being generated...")}</span></ModalHeader>
                    <ModalBody>
                        <div>
                            <span className="font-size-18 me-2">{props.t("PDF is being generated...")}</span>
                            <Spinner animation="border" variant="success" className="ms-2 text-green" size="sm"/>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>)
}

projectHouseList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(projectHouseList)
)