import axios from "axios";
import {getCookie} from "./cookie_helper";

export const API_URL = 'https://api.selecta-fiber.com/api';
// export const API_URL = 'http://localhost:5100/api';

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);

function setAccessToken() {
  const token = getCookie("access_token");
  if (token) {
    axiosApi.defaults.headers.common["x-access-token"] = token;
  }
}

export async function get(url, config = {}) {
  setAccessToken();
  axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  try {
    let response = await axiosApi.get(url, { ...config });
    return response.data;
  } catch (e) {
    return {
      status:false,
      message:e.message,
      data: []
    }
  }
}

export async function getFile(url, config = {}) {
  setAccessToken();
  axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  try {
    let response = await axiosApi.get(url, { ...config });
    return response;
  } catch (e) {
    return {
      status:false,
      message:e.message,
      data: []
    }
  }
}

export async function post(url, body = {}, config = {}) {
  setAccessToken();
  axiosApi.defaults.headers.common["Content-Security-Policy"] = "upgrade-insecure-requests";
  try {
    const response = await axiosApi.post(url, {...body}, {...config});
    return response.data;
  } catch (e) {
    return {
      status:false,
      message:e.message,
      data: []
    }
  }
}

export async function put(url, data, config = {}) {
  setAccessToken();
  axiosApi.defaults.headers.common["Content-Security-Policy"] = "upgrade-insecure-requests";
  try {
    const response = await axiosApi.put(url, {...data}, {...config});
    return response.data;
  } catch (e) {
    return {
      status:false,
      message:e.message,
      data: []
    }
  }
}

export async function del(url, config = {}) {
  setAccessToken();
  axiosApi.defaults.headers.common["Content-Security-Policy"] = "upgrade-insecure-requests";
  try {
    let response = await axiosApi.delete(url, { ...config });
    return response.data;
  } catch (e) {
    return {
      status: false,
      message: e.message,
      data: []
    }
  }
}
