import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useFormik} from "formik";
import {postCompanyListService} from "../../services/panel/companyService";
import {error, notify} from "../../components/toastify";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'
import {getProjectListService} from "../../services/panel/projectService";


const aggrementType = [
    {value: 'METER', label: 'METER'},
    {value: 'HOUR', label: 'HOUR'},
    ];
const AddCompany = (props) => {
    const {show, toggle, onAdding,projectList} = props;
    const [value, setValue] = useState()
    const [project, setProject] = useState([])
    useEffect(() => {
        getProjectListService().then((response)=>{
            const Project = response.data?.map((item) => {
                return {value: item.id, label: item.code}
            })
            setProject(Project)
        })
    }, []);

    const addCompany = useFormik({
        initialValues: {
            name: '',
            aggrement_type: '',
            owner_name: '',
            owner_surname: '',
            phone: '',
            address: '',
            price: '',
            project_id: '',
        },
        onSubmit: ((values, {resetForm}) => {
            values.phone = value
            postCompanyListService(values).then((res) => {
                if (!res.status) return error(res.message.code)
                notify()
                toggle();
                resetForm()
                onAdding(true);
            })
        })
    })

    return (
        <React.Fragment>
            <Modal isOpen={show} toggle={toggle} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form className="form-horizontal" onSubmit={(e) => {
                    e.preventDefault();
                    addCompany.handleSubmit();
                }}>
                    <ModalHeader toggle={toggle}><span
                        className="float-center">{props.t("Add New Company")}</span></ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label htmlFor="Project">{props.t("Project List")}</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="Project"
                                isSearchable={true}
                                isClearable={true}
                                onChange={(selectedOption)=>{
                                    addCompany.setFieldValue('project_id', selectedOption.value);
                                }}
                                onBlur={addCompany.handleBlur}
                                options={project}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="name">{props.t("Name")}</label>
                            <Input
                                name="name"
                                className="form-control"
                                type="text"
                                maxLength={50}
                                onChange={addCompany.handleChange}
                                onBlur={addCompany.handleBlur}
                                value={addCompany.values.name || ""}
                                required={true}
                            />
                        </FormGroup>
                        <Row>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="price">{props.t("Price")}</label>
                                    <Input
                                        name="price"
                                        className="form-control"
                                        type="number"
                                        maxLength={50}
                                        onChange={addCompany.handleChange}
                                        onBlur={addCompany.handleBlur}
                                        value={addCompany.values.price || ""}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="aggrement_type">{props.t("Aggrement Type")}</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="aggrement_type"
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={(e) => {
                                            addCompany.setFieldValue('aggrement_type', e.value);
                                        }}
                                        onBlur={addCompany.handleBlur}
                                        options={aggrementType}
                                        placeholder={props.t("Select Type")}
                                        required={true}
                                    >
                                    </Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="owner_name">{props.t("Owner Name")}</label>
                                    <Input
                                        name="owner_name"
                                        className="form-control"
                                        type="text"
                                        maxLength={50}
                                        onChange={addCompany.handleChange}
                                        onBlur={addCompany.handleBlur}
                                        value={addCompany.values.owner_name || ""}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={6}>
                                <FormGroup>
                                    <label htmlFor="owner_surname">{props.t("Owner LastName")}</label>
                                    <Input
                                        name="owner_surname"
                                        className="form-control"
                                        type="text"
                                        maxLength={50}
                                        onChange={addCompany.handleChange}
                                        onBlur={addCompany.handleBlur}
                                        value={addCompany.values.owner_surname || ""}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label htmlFor="address">{props.t("Address")}</label>
                            <Input
                                name="address"
                                className="form-control"
                                type="text"
                                onChange={addCompany.handleChange}
                                onBlur={addCompany.handleBlur}
                                value={addCompany.values.address || ""}
                                required={true}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="phone">{props.t("Phone")}</label>
                            <PhoneInput
                                name="phone"
                                maxLength={50}
                                onChange={setValue}
                                value={value}
                                required={true}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="form-footer">
                        <Button
                            block
                            color="success"
                            className="btn-green"
                            type="submit"
                        >
                            {props.t("Add Company")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>)
}

AddCompany.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AddCompany)
)