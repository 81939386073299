import React, {useEffect, useState} from "react";
import "devextreme/dist/css/dx.light.css";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Editing, Scheduler, Scrolling,} from "devextreme-react/scheduler";
import {getUserAddressService, getUserListService,} from "../../services/panel/userService";
import Select from "react-select";
import {error, notify} from "../../components/toastify";
import {dummyFormData} from "./dummyFormItem";
import {
    addTerminService,
    delTerminService,
    getTerminScheduleService,
    updateTerminService
} from "../../services/panel/terminService";
import DataGridLoadPanel from "devextreme-react/load-panel";

const views = ['week', 'month'];

const TerminSchedule = (props) => {
    const position = {of: '#scheduler', at: 'center', my: 'center'};
    const [loading, setLoading] = useState(true)
    const [terminData, setTerminData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [addressData, setAddressData] = useState([])
    const onAppointmentFormOpening = async (e) => {
        const {form} = e;
        form.option('items', dummyFormData());
        let AddressOptions;
        if (e.appointmentData.user_id) {
            const response = await getUserAddressService(e.appointmentData.user_id);
            AddressOptions = response.data?.map((item) => {
                return {value: item.id, label: item.address};
            })
        }
        form.option('items', [{
            colSpan: 1,
            label: {text: props.t("Customer Name")},
            editorType: "dxTextBox",
            dataField: "customer_name",
            validationRules: [{type: "required",}]
        }, {
            colSpan: 1,
            label: {text: props.t("Customer LastName")},
            editorType: "dxTextBox",
            dataField: "customer_lastname",
            validationRules: [{type: "required",}]
        }, {
            colSpan: 2,
            label: {text: props.t("Customer Email")},
            editorType: "dxTextBox",
            dataField: "customer_email",
            editorOptions: {mode: "email"},
            validationRules: [{type: "required",}]
        }, {
            label: {text: props.t("Start Date")},
            dataField: 'startDate',
            editorType: 'dxDateBox',
            validationRules: [{type: "required",}],
            editorOptions: {
                width: '100%',
                type: 'datetime',
            },
        }, {
            label: {text: props.t("End Date")},
            name: 'endDate',
            dataField: 'endDate',
            editorType: 'dxDateBox',
            validationRules: [{type: "required",}],

            editorOptions: {
                width: '100%',
                type: 'datetime',
            },
        }, {
            colSpan: 1,
            label: {text: props.t("Customer Phone")},
            editorType: "dxTextBox",
            dataField: "customer_phone",
            validationRules: [{type: "required",}]
        }, {
            colSpan: 1,
            label: {text: props.t("Customer Home Phone")},
            editorType: "dxTextBox",
            dataField: "customer_home_phone",
        }, {
            colSpan: 2,
            label: {text: props.t("User")},
            editorType: "dxSelectBox",
            dataField: "user_id",
            validationRules: [{type: "required",}],
            editorOptions: {
                items: userData,
                displayExpr: "label",
                searchEnabled: true,
                valueExpr: "value",
                onValueChanged: function (args) {
                    e.form.updateData("title", args.component.option().displayValue);
                    getUserAddressService(args.value).then((res) => {
                        AddressOptions = res.data?.map((item) => {
                            return {value: item.id, label: item.address}
                        })
                        form.getEditor('house_id').option('items', AddressOptions)
                    })
                }
            }
        }, {
            colSpan: 2,
            label: {text: props.t("House")},
            editorType: "dxSelectBox",
            dataField: "house_id",
            validationRules: [{type: "required",}],
            editorOptions: {
                items: AddressOptions,
                searchEnabled: true,
                displayExpr: "label",
                valueExpr: "value",
                onValueChanged: function (args) {
                }
            }
        }, {
            colSpan: 2,
            label: {text: props.t("Status")},
            editorType: "dxSelectBox",
            dataField: "status",
            editorOptions: {
                items: status,
                displayExpr: "label",
                defaultValue: 0,
                valueExpr: "value",
                onValueChanged: function (args) {
                }
            }
        },
            {
                colSpan: 2,
                label: {text: props.t("Termin Note")},
                editorType: "dxTextArea",
                dataField: "terminNote",
            }
        ]);
    };
    const updateTermin = (value) => {
        if (!value) return null
        getTerminScheduleService().then((response) => {
            setTerminData(response.data)
        })
    }
    const onButtonClick = (id) => {
        getUserAddressService(id).then((res) => {
            const AddressOptions = res.data?.map((item) => {
                return {value: item.id, label: item.address}
            })
            setAddressData(AddressOptions)
        })
        const filteredData = mainData.filter((item) => {
            return item.user_id === id;
        });
        setTerminData(filteredData)
        if (filteredData.length === 0) return null;

    };
    const status = [
        {value: 0, label: props.t("Pending")},
        {value: 1, label: props.t("Completed")},
        {value: -1, label: props.t("Canceled")},
    ]
    const onAppointmentAdding = (e) => {
        addTerminService(e.appointmentData).then((res) => {
            if (!res.status) return error(res.message)
            notify()
            updateTermin(true)
        })
    }
    const onAppointmentDeleted = (e) => {
        delTerminService(e.appointmentData.id).then((res) => {
            if (!res.status) return error(res.message)
            notify()
            updateTermin(true)
        })
    }
    const onAppointmentUpdated = (e) => {
        updateTerminService(e.newData).then((res) => {
            if (!res.status) return error(res.message)
            notify()
            updateTermin(true)
        })
    }
    const onAppointmentRendered = (args) => {
        switch (args.appointmentData.status) {
            case 0:
                args.appointmentElement.style.backgroundColor = "#ff8704";
                break;
            case 1:
                args.appointmentElement.style.backgroundColor = "#08df90";
                break;
            case -1:
                args.appointmentElement.style.backgroundColor = "#FF0000";
        }
    };
    useEffect(() => {
        getUserListService().then((response) => {
            const userList = response.data?.map((item) => {
                return {value: item.id, label: item.name + " " + item.lastname}
            })
            setUserData(userList)
        })
        getTerminScheduleService().then((response) => {
            if(response){
                setTerminData(response.data)
                setMainData(response.data)
                setLoading(false)
            }
        })
    }, []);
    return (
        <div className="mt-2">
            <div className="page-content">
                <DataGridLoadPanel
                    visible={loading}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    shading={false}
                    height={100}
                    width={140}
                    message="Loading..."
                    position={position}
                />
                <div style={{width: "200px"}}>
                    <Select
                        defaultValue={null}
                        className="basic-single schedule-select"
                        classNamePrefix="select"
                        name="Company"
                        onChange={(e) => {
                            onButtonClick(e.value)
                        }}
                        options={userData}
                    />
                </div>
                <div className="font-size-20 semi-bold">{props.t("Termin List")} / {props.t("Termin Schedule")}</div>

                <Scheduler
                    timeZone="Etc/UTC"
                    id="scheduler"
                    dataSource={terminData}
                    textExpr="title"
                    defaultCurrentView="week"
                    views={views}
                    defaultCurrentDate={new Date()}
                    showAllDayPanel={false}
                    startDayHour={8}
                    endDayHour={18}
                    onAppointmentDeleted={onAppointmentDeleted}
                    onAppointmentAdding={onAppointmentAdding}
                    onAppointmentUpdated={onAppointmentUpdated}
                    onAppointmentFormOpening={onAppointmentFormOpening}
                    onAppointmentUpdating={onAppointmentUpdated}
                    width={'100%'}
                    className="mt-4"
                    onAppointmentRendered={onAppointmentRendered}

                >
                    <Editing
                        allowDragging={false}
                        allowResizing={false}
                    />
                    <Scrolling
                        mode='horizontal'
                    />
                </Scheduler>
            </div>
        </div>
    );
}

TerminSchedule.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(TerminSchedule));
