import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import "../src/assets/scss/custom.scss"
import 'react-phone-number-input/style.css'

import PropTypes from 'prop-types';
import React from "react";

import {BrowserRouter as Router, Switch} from "react-router-dom";
import {connect} from "react-redux";

// Import Routes all
import {authProtectedRoutes, errorRoutes, publicRoutes} from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";
import HorizontalLayout from "./components/HorizontalLayout";

// Import scss
import "./assets/scss/theme.scss";
import 'react-toastify/dist/ReactToastify.css';

const App = props => {

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

  function getLayout() {
    let layoutCls = HorizontalLayout;
    switch (props.layout.layoutType) {
      default:
        layoutCls = HorizontalLayout;
        break;
    }
    return HorizontalLayout;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          {errorRoutes.map((route, idx) => (
              <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
              />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
