import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button as RsButton, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from "devextreme-react/data-grid"
import {delCompanyListService, getCompanyListService} from "../../services/panel/companyService";
import {formatDate} from "../../helpers/formatDate";
import AddCompany from "./addCompany";
import DeleteModal from "../../components/delModal";
import {error, notify} from "../../components/toastify";
import DataGridLoadPanel from "devextreme-react/load-panel";

const CompanyList = (props) => {
    const position = {of: '#datagrid', at: 'center', my: 'center'};
    const [companyList, setCompanyList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getCompanyListService().then((response)=>{
            if(response){
                setCompanyList(response.data)
                setLoading(false)
            }
        })
    }, []);

    const Submit = (value) => {
        if(!value) return null;
        getCompanyListService().then((res)=>{
            setCompanyList(res.data)
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="dash">
                    <DataGridLoadPanel
                        visible={loading}
                        shadingColor="rgba(0, 0, 0, 0.5)"
                        shading={false}
                        height={100}
                        width={140}
                        message="Loading..."
                        position={position}
                    />
                    <h4>{props.t("Company")}</h4>
                    <Row className="mt-2">
                        <Col xl={12} className="mt-2">
                            <div className="shadow bg-white p-3 rounded-4 mt-2">
                                <DataGrid
                                    allowColumnReordering={true}
                                    id="companyGrid"
                                    showBorders={false}
                                    className="mt-4"
                                    dataSource={companyList}
                                    columnAutoWidth={true}
                                    showColumnLines={false}
                                    showRowLines={true}
                                >
                                    {/*<StateStoring enabled={true} type="localStorage" storageKey="companyGrid" />*/}
                                    <LoadPanel enabled={false}/>
                                    <Paging defaultPageSize={10}/>
                                    <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true} allowedPageSizes={[5,10,20]}/>
                                    <Grouping contextMenuEnabled={true}/>
                                    <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                    <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                    <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                    <HeaderFilter visible={true}/>
                                    <Toolbar>
                                        <Item location="before"
                                              name="groupPanel"
                                        />
                                        <Item location="after"
                                              name="columnChooserButton"
                                        />
                                        <Item location="after">
                                            <div className="btn btn-green btn btn-sm btn-semi-bold" onClick={() => {
                                                setAddModal(true)
                                                }}><i
                                                className="fa fa-plus font-size-16 me-2"/>{props.t("Add Company")}
                                            </div>
                                        </Item>
                                    </Toolbar>
                                    <Column dataField="Transaction" caption={props.t("Transaction")} width={100} type="buttons"
                                            cellRender={object => {
                                                const Data = object.row.data
                                                return (
                                                    <div className="text-center">
                                                            <RsButton className="btn btn-sm btn-danger bg-white border-0"
                                                                      onClick={() => {
                                                                          setSelectedCompany(Data)
                                                                          setIsDelete(true);
                                                                      }}>
                                                                <i className="fa fa-trash trash-icon"/></RsButton>
                                                    </div>
                                                )
                                            }}></Column>
                                    <Column dataField="id" dataType="string" caption={props.t("ID")}/>
                                    <Column dataField="name" caption={props.t("Name")}/>
                                    <Column dataField="price" caption={props.t("Price")}/>
                                    <Column dataField="aggrement_type" caption={props.t("Aggrement Type")}/>
                                    <Column dataField="owner_name" caption={props.t("Owner Name")}/>
                                    <Column dataField="owner_surname" caption={props.t("Owner LastName")}/>
                                    <Column dataField="phone" caption={props.t("Phone")}/>
                                    <Column dataField="address" caption={props.t("Address")}/>
                                    <Column dataField="created_at" caption={props.t("Created At")} cellRender={(object) => {
                                        return <span>{formatDate(object.data.created_at)}</span>
                                    }}/>
                                    <Column dataField="updated_at" caption={props.t("Update At")} cellRender={(object) => {
                                        if(!object.data.updated_at) return <span></span>
                                        return <span>{formatDate(object.data.updated_at)}</span>
                                    }}/>
                                </DataGrid>
                            </div>
                            {selectedCompany && (
                                <div>
                                    <DeleteModal show={isDelete} toggle={() => setIsDelete(false)} onDelete={(data) => {
                                        delCompanyListService(data.id).then((res) => {
                                            setIsDelete(false)
                                            if (!res.status) return error(res.message)
                                            notify("success")
                                            Submit(true)
                                        })
                                    }} selectedData={selectedCompany} body={props.t("Do you want to delete this Company?")}/>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <AddCompany show={addModal} toggle={()=>setAddModal(false)} onAdding={(value)=>Submit(value)}/>
            </div>
        </React.Fragment>)
}

CompanyList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(CompanyList)
)