import React, {useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useFormik} from "formik";
import {getAddressUserService} from "../../../services/panel/userService";
import Select from "react-select";
import {error, notify} from "../../../components/toastify";
import {addTerminService} from "../../../services/panel/terminService";

const AddTermin = (props) => {
    const {show, toggle, onAdding, addressData} = props;
    const [userData, setUserData] = useState([]);
    const NoUser = [{value: "", label: "No User"}]
    const addTermin = useFormik({
        initialValues: {
            house_id: '',
            user_id: '',
            startDate: '',
            endDate: '',
            customer_name: '',
            customer_lastname: '',
            customer_email: '',
            customer_phone: '',
            customer_home_phone: '',
            house_termin_note: '',
        },
        onSubmit: ((values, {resetForm}) => {
            addTerminService(values).then((res) => {
                toggle();
                resetForm()
                if (!res.status) return error(res.message)
                notify()
                onAdding()
            })
        })
    })

    const handleCountryChange = (e) => {
        addTermin.setFieldValue('house_id', e.value);
        addTermin.setFieldValue('user_id', "")
        getAddressUserService(e.value).then((response) => {
            if (!response.data) return setUserData(NoUser)
            const UserOptions = response.data?.map((item) => {
                return {value: item.id, label: item.name + " " + item.lastname + " (Role: " + item.role + ")"}
            })
            setUserData(UserOptions)
        })
    }

    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Form className="form-horizontal" onSubmit={(e) => {
                e.preventDefault();
                addTermin.handleSubmit();
            }}>
                <ModalHeader toggle={toggle}><span className="float-center">{props.t("Add Termin")}</span></ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="house_id">{props.t("Address")}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="house_id"
                            isSearchable={true}
                            isClearable={false}
                            onChange={handleCountryChange}
                            onBlur={addTermin.handleBlur}
                            options={addressData}
                        >
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="user_id">{props.t("User")}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="user_id"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(e) => {
                                addTermin.setFieldValue('user_id', e.value);
                            }}
                            onBlur={addTermin.handleBlur}
                            options={userData}
                            placeholder={props.t("Select User")}
                            required={true}
                        >
                        </Select>
                    </FormGroup>
                    <Row className="mt-1">
                        <Col xl={6}>
                            <FormGroup>
                                <label htmlFor="house_termin_start_date">{props.t("Start Date")}</label>
                                <Input
                                    name="house_termin_start_date"
                                    className="form-control"
                                    type="datetime-local"
                                    step="1"
                                    onChange={(e) => {
                                        addTermin.setFieldValue('startDate', e.target.value);
                                    }}
                                    onBlur={addTermin.handleBlur}
                                    required={true}

                                />
                            </FormGroup>
                        </Col>
                        <Col xl={6}>
                            <FormGroup>
                                <label htmlFor="house_termin_end_date">{props.t("End Date")}</label>
                                <Input
                                    name="house_termin_end_date"
                                    className="form-control"
                                    type="datetime-local"
                                    step="1"
                                    onChange={(e) => {
                                        addTermin.setFieldValue('endDate', e.target.value);
                                    }}
                                    onBlur={addTermin.handleBlur}
                                    required={true}

                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col xl={6}>
                            <FormGroup>
                                <label htmlFor="customer_name">{props.t("Customer Name")}</label>
                                <Input
                                    name="customer_name"
                                    className="form-control"
                                    type="text"
                                    maxLength={50}
                                    onChange={addTermin.handleChange}
                                    onBlur={addTermin.handleBlur}
                                    value={addTermin.values.customer_name || ""}
                                    required={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={6}>
                            <FormGroup>
                                <label htmlFor="customer_lastname">{props.t("Customer LastName")}</label>
                                <Input
                                    name="customer_lastname"
                                    className="form-control"
                                    type="text"
                                    maxLength={50}
                                    onChange={addTermin.handleChange}
                                    onBlur={addTermin.handleBlur}
                                    value={addTermin.values.customer_lastname || ""}
                                    required={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <label htmlFor="customer_email">{props.t("Customer Email")}</label>
                        <Input
                            name="customer_email"
                            className="form-control"
                            type="email"
                            max={50}
                            onChange={addTermin.handleChange}
                            onBlur={addTermin.handleBlur}
                            value={addTermin.values.customer_email || ""}
                        />
                    </FormGroup>
                    <Row className="mt-1">
                        <Col xl={6}>
                            <FormGroup>
                                <label htmlFor="customer_phone">{props.t("Customer Phone")}</label>
                                <Input
                                    name="customer_phone"
                                    className="form-control"
                                    type="tel"
                                    maxLength={10}
                                    onChange={addTermin.handleChange}
                                    onBlur={addTermin.handleBlur}
                                    value={addTermin.values.customer_phone || ""}
                                    required={true}
                                />
                            </FormGroup>

                        </Col>
                        <Col xl={6}>
                            <FormGroup>
                                <label htmlFor="customer_home_phone">{props.t("Customer Home Phone")}</label>
                                <Input
                                    name="customer_home_phone"
                                    className="form-control"
                                    type="tel"
                                    maxLength={10}
                                    onChange={addTermin.handleChange}
                                    onBlur={addTermin.handleBlur}
                                    value={addTermin.values.customer_home_phone || ""}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <label htmlFor="house_termin_note">{props.t("Termin Note")}</label>
                        <Input
                            name="house_termin_note"
                            className="form-control"
                            type="textarea"
                            maxLength={50}
                            onChange={addTermin.handleChange}
                            onBlur={addTermin.handleBlur}
                            value={addTermin.values.house_termin_note || ""}
                            style={{height: 100, resize: "none"}}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="form-footer">
                        <Button
                            block
                            color="success"
                            className="btn-green"
                            type="submit"
                        >
                            {props.t("Add Termin")}
                        </Button>
                </ModalFooter>
            </Form>
        </Modal>
    </React.Fragment>)
}

AddTermin.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AddTermin)
)