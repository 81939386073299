import React, { useEffect, useState } from "react"
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap"
import "../../assets/scss/custom.scss"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { getCookie } from "../../helpers/cookie_helper"
import Dropzone from "react-dropzone"
import { useFormik } from "formik"
import upload from "../../assets/images/upload.png"
import Select from "react-select"
import {
    getCastListService,
    getDPListService,
    getHouseListService,
    getProjectListService
} from "../../services/panel/projectService"
import classnames from "classnames"
import { getWorkList } from "../../services/panel/userService"
import axios from "axios"
import { error, notify } from "../../components/toastify"
import { forEach } from "lodash"
import { createZipService, downloadRarService } from "../../services/panel/systemService"

const ImageOptions = [
    {value: 'House', label: 'House'},
    {value: 'Blasen-House', label: 'Blasen House'},
    {value: 'Blasen-Dp', label: 'Blasen DP'},
    {value: 'Has', label: 'Has'},
    {value: 'Racket', label: 'Racket'},
    {value: 'Lassen-House', label: 'Lassen House'},
    {value: 'Lassen-DP', label: 'Lassen DP'},
    {value: 'Lassen-Cast', label: 'Lassen Cast'},

];
const ImageManagement = (props) => {
    const [activeTab, setActiveTab] = useState("1");
    const [projects, setProjects] = useState([]);
    const [castList, setCastList] = useState([]);
    const [dpList, setDpList] = useState([]);
    const [houseList, setHouseList] = useState([]);
    const [workList, setWorkList] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);

    const handleDrop = (acceptedFiles) => {
        formik.setFieldValue('images', acceptedFiles);
    };
    const formik = useFormik({
        initialValues: {
            house: null,
            project: null,
            dp: null,
            cast: null,
            image_type: null,
            images: null,
            work: null
        },
        onSubmit: (values, {resetForm}) => {
            setLoadingModal(true)
            const formData = new FormData();
            const token = getCookie("access_token");
            formData.append('project_id', values.project?.value);
            formData.append('cast_id', values.cast?.value);
            formData.append('dp_id', values.dp?.value);
            formData.append('house_id', values.house?.value);
            formData.append('work_id', values.work?.value);
            forEach(values.images, (image) => {
                formData.append('image', image);
            });
            axios.post(`https://api.selecta-fiber.com/api/image/${values.image_type}`, formData, {
                headers: {
                    'x-access-token': token,
                },
                timeout: 0
            }).then((response) => {
                if (!response.status) return error(response.message)
                notify("Success")
                resetForm()
            })
        },
    });
    const handleProjectChange = (selectedOption) => {
        formik.setFieldValue('project', selectedOption);
        formik.setFieldValue('cast', null);
        formik.setFieldValue('dp', null);
        formik.setFieldValue('house', null);
        getCastListService(selectedOption.value).then((response) => {
            const castOptions = response.data?.map((item) => {
                return {value: item.id, label: item.code, project: selectedOption.value}
            })
            setCastList(castOptions)
        })
    }
    const handleCastChange = (selectedOption) => {
        formik.setFieldValue('cast', selectedOption);
        formik.setFieldValue('dp', null);
        formik.setFieldValue('house', null);
        getDPListService(selectedOption.project, selectedOption.value).then((response) => {
            const dpOptions = response.data?.map((item) => {
                return {value: item.id, label: item.code, project: selectedOption.project, cast: selectedOption.value}
            })
            setDpList(dpOptions)
        })
    }
    const handleDpChange = (selectedOption) => {
        formik.setFieldValue('dp', selectedOption);
        formik.setFieldValue('house', null);
        getHouseListService(selectedOption.project, selectedOption.cast, selectedOption.value).then((response) => {
            const houseOptions = response.data?.map((item) => {
                return {value: item.house_id, label: `${item.street} - ${item.house_no} ${item.house_no_extra}`}
            })
            setHouseList(houseOptions)
        })
    }
    useEffect(() => {
        getProjectListService().then((response) => {
            const projectOption = response.data?.map((item) => {
                return {value: item.id, label: item.code}
            })
            setProjects(projectOption)
        })
        getWorkList().then((response) => {
            const workList = response.data?.map((item) => {
                return {value: item.company_id, label: item.company_name}
            })
            setWorkList(workList)
        })
    }, [])

    const downloadImage = async () => {
        createZipService().then(response => {
            if(response){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'downloaded_images.zip';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setLoadingModal(false)
            }else{
                error("Error")
                setLoadingModal(false)
            }

        })
          .catch(error => {
              console.error('Hata:', error);
          });
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="dash">
                    <div className="my-auto container">
                        <Row className="mt-2">
                            <Col xl={12} className="mt-2">
                                <div className="d-flex justify-content-end">
                                    <Button color="success" size="MD"
                                            onClick={() => {
                                                setLoadingModal(true)
                                                downloadImage()
                                            }}>All Images</Button>
                                </div>
                                <div className="shadow bg-white p-3 rounded-4 mt-2">
                                    <Navbar expand="md" className="nav-tabs-custom" style={{ marginTop: "-24px" }}>
                                        <Nav role="tablist" className="nav nav-tabs border-0 pt-2">
                                            <NavItem className="font-size-14">
                                                <NavLink
                                                  className={classnames({ active: activeTab === "1", })}
                                                  onClick={() => {
                                                      formik.resetForm()
                                                      setActiveTab("1")
                                                  }}>
                                                    {props.t("Project")}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="font-size-14">
                                                <NavLink
                                                  className={classnames({ active: activeTab === "2", })}
                                                  onClick={() => {
                                                      formik.resetForm()
                                                      setActiveTab("2")
                                                  }}
                                                >
                                                    {props.t("Worklist")}
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Navbar>
                                    <Form onSubmit={formik.handleSubmit} className="mt-2">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <FormGroup className="mt-3">
                                                    <label htmlFor="country">
                                                        {props.t("Project")}
                                                    </label>
                                                    <Select
                                                      className="basic-single"
                                                      classNamePrefix="select"
                                                      name="project"
                                                      isSearchable={false}
                                                      onChange={handleProjectChange}
                                                      onBlur={formik.handleBlur}
                                                      options={projects}
                                                      value={formik.values.project}
                                                      required={activeTab == "1"}
                                                    />
                                                </FormGroup>
                                                <div className="d-flex">
                                                    <FormGroup className="w-100">
                                                        <label htmlFor="cast">
                                                            {props.t("Cast")}
                                                        </label>
                                                        <Select
                                                          className="basic-single"
                                                          classNamePrefix="select"
                                                          name="cast"
                                                          isSearchable={true}
                                                          isClearable={true}
                                                          onChange={handleCastChange}
                                                          onBlur={formik.handleBlur}
                                                          options={castList}
                                                          value={formik.values.cast}
                                                          required={activeTab == "1"}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="w-100 ms-2">
                                                        <label htmlFor="dp">
                                                            {props.t("DP")}
                                                        </label>
                                                        <Select
                                                          className="basic-single w-100"
                                                          classNamePrefix="select"
                                                          name="dp"
                                                          isSearchable={true}
                                                          isClearable={true}
                                                          onChange={handleDpChange}
                                                          onBlur={formik.handleBlur}
                                                          options={dpList}
                                                          value={formik.values.dp}
                                                          required={activeTab == "1"}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="w-100 ms-2">
                                                        <label htmlFor="house">
                                                            {props.t("House")}
                                                        </label>
                                                        <Select
                                                          className="basic-single w-100"
                                                          classNamePrefix="select"
                                                          name="house"
                                                          isSearchable={true}
                                                          isClearable={true}
                                                          onChange={(selectedOption) => {
                                                              formik.setFieldValue('house', selectedOption);
                                                          }}
                                                          onBlur={formik.handleBlur}
                                                          options={houseList}
                                                          value={formik.values.house}
                                                          // required={activeTab == "1"}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <FormGroup>
                                                    <label htmlFor="image_type">
                                                        {props.t("Image Type")}
                                                    </label>
                                                    <Select
                                                      className="basic-single"
                                                      classNamePrefix="select"
                                                      name="image_type"
                                                      isSearchable={true}
                                                      isClearable={true}
                                                      onChange={(selectedOption) => {
                                                          formik.setFieldValue('image_type', selectedOption.value);
                                                      }}
                                                      onBlur={formik.handleBlur}
                                                      options={ImageOptions}
                                                      required={activeTab == "1"}
                                                    />
                                                </FormGroup>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <FormGroup className="w-100 ms-2">
                                                    <label htmlFor="work">
                                                        {props.t("Works")}
                                                    </label>
                                                    <Select
                                                      className="basic-single w-100"
                                                      classNamePrefix="select"
                                                      name="work"
                                                      isSearchable={true}
                                                      isClearable={true}
                                                      onChange={(selectedOption) => {
                                                          formik.setFieldValue('work', selectedOption);
                                                          formik.setFieldValue('image_type', "Work");
                                                      }}
                                                      onBlur={formik.handleBlur}
                                                      options={workList}
                                                      value={formik.values.work}
                                                      required={activeTab == "2"}
                                                    />
                                                </FormGroup>
                                            </TabPane>
                                        </TabContent>
                                        <Dropzone onDrop={handleDrop}
                                                  accept={[
                                                      "image/jpeg",
                                                      "image/png",
                                                      "image/jpg"
                                                  ]}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                              <div>
                                                  <div {...getRootProps()}
                                                       className="dropzone dropzone-data dropzone-radius p-5">
                                                      <input {...getInputProps()} required={true} />
                                                      {isDragActive ? (
                                                        <p className="dropzone-content">
                                                            <img className="img-size" src={upload} alt="" />
                                                            <br />{props.t("Drop File")}</p>
                                                      ) : (
                                                        <p className="dropzone-content">
                                                            <img src={upload} alt="" />
                                                            <br />{props.t("Drag and Drop")}
                                                        </p>
                                                      )}
                                                  </div>
                                                  <aside>
                                                      <Row className="d-flex mt-2">
                                                          {formik.values.images && formik.values.images.length > 0 && (
                                                            formik.values.images.map((file, i) => (
                                                              <Col lg={3} className="thumb shadow p-3" key={i}>
                                                                  <div className="thumbInner">
                                                                      <Button color="danger" size="sm"
                                                                              className="position-absolute"
                                                                              onClick={() => {
                                                                                  formik.setFieldValue('images', formik.values.images.filter((item, index) => index !== i))
                                                                              }}>X</Button>
                                                                      <div className="h-100">
                                                                          <img
                                                                            src={URL.createObjectURL(file)}
                                                                            className="thumbImg img-fluid"
                                                                            onLoad={() => {
                                                                                URL.revokeObjectURL(file.preview)
                                                                            }}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div
                                                                    className="text-white bg-dark text-center mt-auto">
                                                                      {file.name}
                                                                  </div>
                                                              </Col>
                                                            )))}
                                                      </Row>
                                                  </aside>
                                              </div>

                                            )}
                                        </Dropzone>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-green w-100 mt-3">
                                                {props.t("Submit")}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal isOpen={loadingModal} toggle={() => {
                setLoadingModal(false)
            }} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <ModalHeader toggle={() => {
                    setLoadingModal(false)
                }}><span className="float-center">{props.t("")}</span></ModalHeader>
                <ModalBody>
                    <div>
                        <span className="font-size-18 me-2">{props.t("Please Wait Downloading All Images...")}</span>
                        <Spinner animation="border" variant="success" className="ms-2 text-green" size="sm"/>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
ImageManagement.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
  withTranslation()(ImageManagement)
)
