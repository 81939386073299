import React, {useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    Navbar,
    NavItem,
    NavLink
} from "reactstrap";
import classnames from "classnames";
import notFoundImage from "../../../assets/images/not-found.png";

const ImageModal = (props) => {
    const {show, toggle,selectedImage,title } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [allImage, setAllImage] = useState(selectedImage.house_images);

    const checkImage = (data) =>{
        if (data.length > 0) return <i className="far fa-check-circle text-success"/>
        else return <i className="far fa-times-circle text-danger"/>
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === allImage.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? allImage.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const Slide = allImage.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item}
            >
                <img
                    className="h-100 w-100"
                    src={"https://file-bucket-selecta.s3.eu-central-1.amazonaws.com/" + item}
                    onError={(event) => {
                        event.target.src = notFoundImage
                    }}
                    border="0" style={{objectFit: "cover"}} alt="selecta"/>
            </CarouselItem>
        );
    });
    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="lg"
               aria-labelledby="contained-modal-title-vcenter" centered>
            <ModalHeader toggle={toggle}><span
                className="float-center">{props.t(title)}</span></ModalHeader>
            <ModalBody>
                <div>
                    <Navbar expand="md" className="nav-tabs-custom" style={{marginTop: "-24px"}}>
                        <Nav role="tablist" className="nav nav-tabs border-0 pt-2">
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "1",})}
                                    onClick={() => {
                                        setActiveTab("1")
                                        setAllImage(selectedImage.house_images)
                                    }}
                                >
                                    {props.t("House")} {selectedImage ? checkImage(selectedImage.house_images) : ""}
                                </NavLink>
                            </NavItem>
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "2",})}
                                    onClick={() => {
                                        setActiveTab("2")
                                        setAllImage(selectedImage.racket_images)
                                    }}
                                >
                                    {props.t("Racket")} {selectedImage ? checkImage(selectedImage.racket_images) : ""}
                                </NavLink>
                            </NavItem>
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "3",})}
                                    onClick={() => {
                                        setActiveTab("3")
                                        setAllImage(selectedImage.blasen_house_images)
                                    }}
                                >
                                    {props.t("Blasen")} {selectedImage ? checkImage(selectedImage.blasen_house_images) : ""}
                                </NavLink>
                            </NavItem>
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "3",})}
                                    onClick={() => {
                                        setActiveTab("3")
                                        setAllImage(selectedImage.blasen_dp_images)
                                    }}
                                >
                                    {props.t("Blasen DP")} {selectedImage ? checkImage(selectedImage.blasen_dp_images) : ""}
                                </NavLink>
                            </NavItem>
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "4",})}
                                    onClick={() => {
                                        setActiveTab("4")
                                        setAllImage(selectedImage.lassen_cast_images)
                                    }}
                                >
                                    {props.t("Lassen Cast")} {selectedImage ? checkImage(selectedImage.lassen_cast_images) : ""}
                                </NavLink>
                            </NavItem>
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "5",})}
                                    onClick={() => {
                                        setActiveTab("5")
                                        setAllImage(selectedImage.lassen_dp_images)
                                    }}
                                >
                                    {props.t("Lassen DP")} {selectedImage ? checkImage(selectedImage.lassen_dp_images) : ""}
                                </NavLink>
                            </NavItem>
                            <NavItem className="font-size-14">
                                <NavLink
                                    className={classnames({active: activeTab === "6",})}
                                    onClick={() => {
                                        setActiveTab("6")
                                        setAllImage(selectedImage.has_images)
                                    }}
                                >
                                    {props.t("Has")} {selectedImage ? checkImage(selectedImage.has_images) : ""}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Navbar>
                </div>
                {allImage.length === 0 ? <img className="h-100 w-100" src={notFoundImage} border="0" style={{objectFit: "cover"}} alt="selecta"/>
                    :
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        className="mt-3"
                    >
                        <CarouselIndicators
                            items={allImage}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                        />
                        {allImage ? Slide : ""}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            className="text-primary"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            className="text-green"
                            onClickHandler={next}
                        />
                    </Carousel>
                }
            </ModalBody>
        </Modal>
    </React.Fragment>)
}

ImageModal.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(ImageModal)
)