import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button as RsButton, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Button,
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {
    delProjectUserListService,
    getProjectNotUserListService,
    getProjectUserListService
} from "../../../services/panel/projectService";
import {badgeColorStatus} from "../../../constants/badgecolor";
import AddModal from "./addModal";
import {error, notify} from "../../../components/toastify";
import DeleteModal from "../../../components/delModal";
import DataGridLoadPanel from "devextreme-react/load-panel";

const projectUserList = (props) => {
    const position = {of: '#projectUserList', at: 'center', my: 'center'};
    const [loading, setLoading] = useState(true)
    const [projectUserListData, setProjectUserListData] = useState(null)
    const [userListData, setUserListData] = useState(null)
    const [selectedUserData, setSelectedUserData] = useState(null)
    const [isAdding, setIsAdding] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    let location = useLocation();
    let params = new URLSearchParams(location.search);

    useEffect(() => {
        getProjectUserListService(params.get("projectID")).then((response) => {
            if(response){
                setProjectUserListData(response.data)
                setLoading(false)
            }
        })
    }, [])

    const Submit = () => {
        getProjectUserListService(params.get("projectID")).then((response) => {
            if(response){
                setProjectUserListData(response.data)
                setLoading(false)
            }
        })
    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid className="dash">
                <DataGridLoadPanel
                    visible={loading}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    shading={false}
                    height={100}
                    width={140}
                    message="Loading..."
                    position={position}
                />
                <h4>{params.get("projectCode")} - {params.get("desc")}</h4>
                <Row className="mt-2">
                    <Col xl={12} className="mt-2">
                        <div className="shadow bg-white p-3 rounded-4 mt-2">
                            <DataGrid
                                allowColumnReordering={true}
                                showBorders={false}
                                className="mt-4"
                                dataSource={projectUserListData}
                                columnAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                id="projectUserList"
                            >
                                <StateStoring enabled={true} type="localStorage" storageKey="projectUserList" />
                                <LoadPanel enabled={false}/>
                                <Paging defaultPageSize={10}/>
                                <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true} allowedPageSizes={[5,10,20]}/>
                                <Grouping contextMenuEnabled={true}/>
                                <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                <HeaderFilter visible={true}/>
                                <Toolbar>
                                    <Item location="before" name="groupPanel"/>
                                    <Item location="after" name="columnChooserButton"/>
                                    <Item location="after">
                                        <RsButton outline color="success" className="btn" onClick={() => {
                                            getProjectNotUserListService(params.get("projectID")).then((response) => {
                                                if (!response.data) return error("All User Taken");
                                                setUserListData(response.data)
                                                setIsAdding(true)
                                            })}}>
                                            <i className="fa fa-plus"/>
                                        </RsButton>
                                    </Item>
                                </Toolbar>
                                <Column dataField="" caption={props.t("")} width={150} type="buttons">
                                    <Button cssClass="fa fa-trash trash-icon" onClick={(object) => {
                                        setSelectedUserData(object.row.data)
                                        setIsDeleting(true)}}>
                                    </Button>
                                </Column>
                                <Column dataField="id" caption={props.t("User ID")} cssClass="bold text-center" dataType="string" visible={false}></Column>
                                <Column dataField="name" caption={props.t("Name")} cssClass="text-center" dataType="string"></Column>
                                <Column dataField="lastname" caption={props.t("LastName")} cssClass="text-center" dataType="string"></Column>
                                <Column dataField="email" caption={props.t("E-mail")} cssClass="text-center" dataType="string"></Column>
                                <Column dataField="role" caption={props.t("User Role")} cssClass="text-center" dataType="string"></Column>
                                <Column dataField="device_id" caption={props.t("User Device ID")} cssClass="text-center" dataType="string"></Column>
                                <Column dataField="status" caption={props.t("User Status")} cssClass="text-center" dataType="string" cellRender={object => {
                                    const value = object.data.status
                                    return (
                                        <span className="badge badge-pill font-size-12" style={{
                                            backgroundColor: badgeColorStatus(value).backgroundColor,
                                            color: badgeColorStatus(value).color
                                        }}>
                                                {value === true ? props.t("Active") : props.t("Passive")}
                                            </span>
                                    )
                                }}>
                                </Column>
                                <Column dataField="created_at" caption={props.t("Created At")} cssClass="text-center" dataType="date"></Column>
                                <Column dataField="updated_at" caption={props.t("Updated At")} cssClass="text-center" dataType="date"></Column>
                            </DataGrid>
                        </div>
                    </Col>
                </Row>
                {userListData && (
                    <AddModal selectedUser={userListData} show={isAdding} toggle={()=> setIsAdding(false)} onAdding={()=> Submit()} />)}
                {selectedUserData && (
                    <DeleteModal show={isDeleting} toggle={() => setIsDeleting(false)} onDelete={(data) => {
                        delProjectUserListService(params.get("projectID"),data.id).then((res) => {
                            setIsDeleting(false)
                            if (!res.status) return error(res.message)
                            notify()
                            Submit()
                        })
                    }} selectedData={selectedUserData} body={props.t("Do you want to delete this User?")}/>
                )}
            </Container>
        </div>
    </React.Fragment>)
}

projectUserList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(projectUserList)
)