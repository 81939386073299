import React, {useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addProjectUserListService} from "../../../services/panel/projectService";

const AddModal = (props) => {
    const {selectedUser ,show, toggle, onAdding } = props;
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    const [addUser, setAddUser] = useState("");

    return (<React.Fragment>
        <Modal isOpen={show} toggle={toggle} size="sm"
               aria-labelledby="contained-modal-title-vcenter" centered>
            <ModalHeader toggle={toggle}><span
                className="float-center">{props.t("Add New User")}</span></ModalHeader>
            <ModalBody>
                <Form>
                    <Input type="select" onChange={(e) => {
                        setAddUser(e.target.value)
                    }
                    }>
                        <option value={""}>Kullanıcı Seçiniz</option>
                        {selectedUser.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>{item.name} {item.lastname} {item.role}</option>
                            );
                        })}
                    </Input>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button outline color="success" className="btn me-1" onClick={() => {
                    addProjectUserListService(params.get("projectID"), addUser).then(() => {
                        toggle();
                        onAdding(true);
                    })
                }
                }>{props.t("Save")}</Button>
            </ModalFooter>
        </Modal>
    </React.Fragment>)
}

AddModal.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AddModal)
)