import {ADDRESS_URL, GET_USER_LIST, USER_ROLE} from "helpers/url_helper";
import {calculateTimeDifference} from "../../helpers/get_date";

const { post,get,del,put } = require("helpers/api_helper");

export const getUserListService = async () => {
    return await get(GET_USER_LIST);
}

export const getAddressService = async () => {
    return await get(ADDRESS_URL);
}

export const getAddressUserService = async (address_id) => {
    return await get(`/address/${address_id}/user`);
}

export const getUserRoleService = async () => {
    const response = await get(USER_ROLE);
    return response.data;
}

export const addUserService = async (data) =>{
    const body = {
        name: data.name,
        lastname: data.lastName,
        email: data.email,
        password: data.password,
        role: data.Role,
    }
    return await post(GET_USER_LIST, body)
}

export const delUserListService = async (user_id) => {
    return await del(`/user/soft/${user_id}`);
}

export const editUserService = async (data,user_id) =>{
    const body = {
        name: data.editName,
        lastname: data.editLastName,
        role: data.editRole,
        image_url: ""
    }
    return await put(`/user/${user_id}`, body);
}

export const getChiefsWorkList = async (id) => {
    const response = await get(`/user/${id}/work`);
    response.data = response.data.map((item) => {
        item.total_work = calculateTimeDifference(item.start_date,item.end_date)
        return item;
    })
    return response;
}

export const getWorkList = async () => {
    const response = await get(`/work`);
    response.data = response.data.map((item) => {
        item.total_work = calculateTimeDifference(item.start_date,item.end_date)
        return item;
    })
    return response;
}

export const delWork = async (workID) => {
    return await del(`/work/${workID}`);
}

export const getUserAddressService = async (id) => {
    return await get(`/user/${id}/address`);
}