import React from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useFormik} from "formik";
import {error, notify} from "../../../components/toastify";
import {addCountryService} from "../../../services/panel/projectService";

const AddCountry = (props) => {
    const {show, toggle} = props;
    const addCountry = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: ((values, {resetForm}) => {
            addCountryService(values).then((res) => {
                toggle();
                resetForm()
                if (!res.status) return error(res.message)
                notify()
                window.location.reload()

            })
        })
    })

    return (
        <React.Fragment>
            <Modal isOpen={show} toggle={toggle} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form className="form-horizontal" onSubmit={(e) => {
                    e.preventDefault();
                    addCountry.handleSubmit();
                }}>
                    <ModalHeader toggle={toggle}><span
                        className="float-center">{props.t("Country")}</span></ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label htmlFor="name">{props.t("Country")}</label>
                            <Input
                                name="name"
                                className="form-control"
                                type="text"
                                value={addCountry.name}
                                onChange={addCountry.handleChange}
                                onBlur={addCountry.handleBlur}
                                required={true}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="form-footer">
                        <Button
                            block
                            color="success"
                            className="btn-green"
                            type="submit"
                        >
                            {props.t("Add")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>)
}

AddCountry.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(AddCountry)
)