import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import {Collapse} from "reactstrap"

import {Link, withRouter} from "react-router-dom"
import classname from "classnames"

import {withTranslation} from "react-i18next"

import {connect} from "react-redux"

const Navbar = props => {
    const [userManagement, setUserManagement] = useState(false)
    const [reports, setReports] = useState(false)
    const [collection, setCollection] = useState(false)
    const [termin , setTermin] = useState(false)
    const [company , setCompany] = useState(false)
    const [system , setSystem] = useState(false)


    useEffect(() => {
        let matchingMenuItem = null;
        const ul = document.getElementById("navigation");
        const items = ul.getElementsByTagName("a");
        for (const element of items) {
            if (props.location.pathname === element.pathname) {
                matchingMenuItem = element
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    })

    const activateParentDropdown = (item) => {
        const parentElements = [];
        let parent = item.parentElement;
        while (parent) {
            parentElements.push(parent);
            parent = parent.parentElement;
        }

        parentElements.forEach((parent) => {
            parent.classList.add("active");
        });

        return false;
    };
    return (
        <React.Fragment>
            <div className="topnav bg-navbar-green">
                <div className="container-fluid dash">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={props.leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle arrow-none text-green"
                                        to="/"
                                    >
                                        <span className="heer">
                                        <i className="bx bx-home-circle me-2"></i>
                                        {props.t("Dashboard")}
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        onClick={() => {
                                            setCompany(!company)
                                        }}
                                        className="nav-link dropdown-toggle arrow-none text-green"
                                        to="/company"
                                    >
                                        <span className="heer">
                                        <i className="far fa-building me-2"></i>
                                            {props.t("Company")}
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        onClick={() => {
                                            setTermin(!termin)
                                        }}
                                        className="nav-link dropdown-toggle arrow-none text-green"
                                        to="/termins"
                                    >
                                        <span className="heer">
                                        <i className="far fa-calendar-alt me-2"></i>
                                            {props.t("Termin")}
                                            <div className="arrow-down"></div>
                                        </span>
                                    </Link>
                                    <div className={classname("dropdown-menu", {show: termin})}>
                                        <Link
                                            to="/termins"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Termin List")}
                                        </Link>
                                        <Link
                                            to="/termin/schedule"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Termin Schedule")}
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        onClick={() => {
                                            setUserManagement(!userManagement);
                                        }}
                                        to="/users"
                                        className="nav-link dropdown-toggle arrow-none text-green"
                                    >
                                        <span className="heer">
                                        <i className="bx bx-user me-2"></i>
                                            {props.t("User Management")}
                                            </span>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        to="/projects"
                                        onClick={() => {
                                            setCollection(!collection)
                                        }}
                                        className="nav-link dropdown-togglez arrow-none text-green"
                                    >
                                        <span className="heer">
                                        <i className="far fa-folder-open me-2"></i>
                                            {props.t("Project Management")}
                                        <div className="arrow-down"></div>
                                            </span>
                                    </Link>
                                    <div className={classname("dropdown-menu", {show: collection})}>
                                        <Link
                                            to="/projects"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Project List")}
                                        </Link>
                                        <Link
                                            to="/project/add"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Add Project")}
                                        </Link>
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        to="/reports"
                                        onClick={() => {
                                            setReports(!reports);
                                        }}
                                        className="nav-link dropdown-togglez arrow-none text-green"
                                    >
                                        <span className="heer">
                                        <i className="far fa-list-alt me-2"></i>
                                            {props.t("Reports")}
                                            <div className="arrow-down"></div>
                                            </span>
                                    </Link>
                                    <div className={classname("dropdown-menu", {show: reports})}>
                                        <Link
                                            to="/reports"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Reports")}
                                        </Link>
                                        <Link
                                            to="/works"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Worklist")}
                                        </Link>
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        to="/system/image"
                                        onClick={() => {
                                            setSystem(!system);
                                        }}
                                        className="nav-link dropdown-togglez arrow-none text-green"
                                    >
                                        <span className="heer">
                                        <i className="far fa-sun me-2"></i>
                                            {props.t("System Management")}
                                            <div className="arrow-down"></div>
                                            </span>
                                    </Link>
                                    <div className={classname("dropdown-menu", {show: system})}>
                                        <Link
                                            to="/system/image"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Image Management")}
                                        </Link>
                                        <Link
                                            to="/system/color"
                                            className="dropdown-item hover-green"
                                        >
                                            {props.t("Color Management")}
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
}

const mapStatetoProps = state => {
    const {leftMenu} = state.Layout
    return {leftMenu}
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
)