import React from "react"
import "devextreme/dist/css/dx.light.css"
import DataGrid, {Column, Grouping, LoadPanel, Pager, Paging,} from "devextreme-react/data-grid"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";


const UserGrids = (props) => {
    const {userList , t, user} = props
    const FilteredData = userList.filter(data => {
        return data.role === user
    })
    return(
        <DataGrid
            allowColumnReordering={true}
            showBorders={false}
            className="bg-white dashboard-grid"
            dataSource={FilteredData}
            columnAutoWidth={true}
            showColumnLines={false}
            showRowLines={true}
            scrolling={false}
        >
            <LoadPanel enabled={false}/>
            <Paging defaultPageSize={5}/>
            <Pager showInfo={false} showNavigationButtons={true}/>
            <Grouping contextMenuEnabled={true}/>
            <Column dataField="name" caption={t("Name")} cssClass="vertical-middle"
                    dataType="string"></Column>
            <Column dataField="lastname" caption={t("LastName")} cssClass="vertical-middle"
                    dataType="string"></Column>
            <Column dataField="email" caption={t("E-mail")} cssClass="vertical-middle"
                    dataType="string"></Column>
            <Column dataField="role" caption={t("User Role")} cssClass="vertical-middle"
                    dataType="string"></Column>
        </DataGrid>
    )
}

UserGrids.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(UserGrids)
)