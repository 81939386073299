import React from "react"
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap"
import "../../assets/scss/custom.scss"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {useFormik} from "formik";
import {error, notify} from "../../components/toastify";
import {addColorService} from "../../services/panel/systemService";

const ColorManagement = props => {

    const formik = useFormik({
        initialValues: {
            name: null,
            hex_code: null,
            rgb: null,

        },
        onSubmit: (values, {resetForm}) => {
            addColorService(values).then((response) => {
                if (!response.status) {
                    return error(response.message.detail)
                }
                resetForm()
                return notify("Success")
            })
        },
    });
    const hexToRGB = async (hex) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        formik.setFieldValue('rgb', `${r},${g},${b}`)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="dash">
                    <div className="container">
                        <Row className="mt-2">
                            <Col xl={12} className="mt-2">
                                <div className="my-auto shadow bg-white p-3 rounded-4 mt-2">
                                    <h5 className="text-center mb-0">{props.t("Color Management")}</h5>
                                    <hr/>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Label for="name" className="font-size-14">{props.t("Color Name")}</Label>
                                        <FormGroup>
                                            <Input
                                                name="name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                placeholder={props.t("Color Name")}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                name="hex_code"
                                                type="color"
                                                onBlur={formik.handleBlur}
                                                onChange={(e) => {
                                                    formik.setFieldValue("hex_code", e.target.value)
                                                    hexToRGB(e.target.value)
                                                }}
                                                value={formik.values.color}
                                                placeholder="Color"
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <hr/>
                                        <div className="d-flex">
                                            <Button color="success" className="btn-green ms-auto" type="submit">
                                                {props.t("Save")}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
ColorManagement.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(ColorManagement)
)
