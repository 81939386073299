import {API_FAIL, API_SUCCESS, GET_USER_LIST_DATA,} from "./actionTypes"

const INIT_STATE = {
    error: "",
    userList: null
}

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_USER_LIST_DATA:
                    return {
                        ...state,
                        userList: action.payload.data,
                    };
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_USER_LIST_DATA:
                    return {
                        ...state,
                        userList: [],
                        error: action.payload.error
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
}

export default User
