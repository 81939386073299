import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button as RsButton, ButtonGroup, Col, Container, Row} from "reactstrap";
import DataGrid, {
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {delUserListService, getUserListService, getUserRoleService} from "../../services/panel/userService";
import {useSelector} from "react-redux";
import {badgeColorStatus} from "../../constants/badgecolor";
import AddUser from "./addUser";
import EditUser from "./editUser";
import DeleteModal from "../../components/delModal";
import {error, notify} from "../../components/toastify";


const userList = (props) => {
    const navigate = useHistory();
    const [userListData, setUserListData] = useState(null)
    const [userRoleData, setUserRoleData] = useState(null)
    const [isAdd, setIsAdd] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const {userList} = useSelector(state => ({
        userList: state.User.userList,
    }))
    const Submit = () => {
        getUserListService().then((response) => {
            setUserListData(response.data)
        })
    }

    const WorkList = (id) => {
        navigate.push({
            pathname: "/works",
            search: `?userID=${id}`,
        })
    }
    useEffect(() => {
        {
            userList === null ? getUserListService().then((response) => {
                setUserListData(response.data)
            }) : setUserListData(userList)
        }
        getUserRoleService().then((res) => {
            setUserRoleData(res)
        })
    }, [])

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid className="dash">
                <h4>{props.t("User List")}</h4>
                <Row className="mt-2">
                    <Col xl={12} className="mt-2">
                        <div className="shadow bg-white p-3 rounded-4 mt-2">
                            <DataGrid
                                allowColumnReordering={true}
                                showBorders={false}
                                className="mt-4"
                                dataSource={userListData}
                                columnAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                keyExpr="id"
                                id="userList"
                            >
                                <StateStoring enabled={true} type="localStorage" storageKey="userList"/>
                                <LoadPanel enabled={false}/>
                                <Paging defaultPageSize={10}/>
                                <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true}
                                       allowedPageSizes={[5, 10, 20]}/>
                                <Grouping contextMenuEnabled={true}/>
                                <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                <HeaderFilter visible={true}/>
                                <Toolbar>
                                    <Item location="before"
                                          name="groupPanel"
                                    />
                                    <Item location="after"
                                          name="columnChooserButton"
                                    />
                                    <Item location="after">
                                        <button className="btn btn-outline-success" onClick={() => {
                                            setIsAdd(true)
                                        }}><i className="fa fa-plus"/></button>
                                    </Item>
                                </Toolbar>
                                <Column dataField="Transaction" caption={props.t("Transaction")} width={150} type="buttons"
                                        cellRender={object => {
                                            const Data = object.row.data
                                            return (
                                                <div className="text-center">
                                                    <ButtonGroup>
                                                        {Data.role === "CHIEFS" && (
                                                            <RsButton className="btn btn-sm btn-secondary bg-white border-0"
                                                                      onClick={() => {WorkList(Data.id)}}>
                                                                <i className="fas fa-info-circle text-black"/></RsButton>
                                                        )}
                                                        <RsButton className="btn btn-sm btn-warning bg-white border-0"
                                                                  onClick={() => {
                                                                      setSelectedUser(Data);
                                                                      setIsEdit(true);
                                                                  }}><i className="fa fa-pen edit-icon"/></RsButton>

                                                        <RsButton className="btn btn-sm btn-danger bg-white border-0"
                                                                  onClick={() => {
                                                                      setSelectedUser(Data);
                                                                      setIsDelete(true);
                                                                  }}>
                                                            <i className="fa fa-trash trash-icon"/></RsButton>
                                                    </ButtonGroup>
                                                </div>
                                            )
                                        }}></Column>
                                <Column dataField="id" caption={props.t("User ID")}
                                        cssClass="text-center bold vertical-middle"
                                        dataType="string" visible={false}></Column>
                                <Column dataField="name" caption={props.t("Name")} cssClass="vertical-middle"
                                        dataType="string"></Column>
                                <Column dataField="lastname" caption={props.t("LastName")} cssClass="vertical-middle"
                                        dataType="string"></Column>
                                <Column dataField="image_url" caption={props.t("Image Url")} cssClass="vertical-middle"
                                        dataType="string" visible={false}></Column>
                                <Column dataField="email" caption={props.t("E-mail")} cssClass="vertical-middle"
                                        dataType="string"></Column>
                                <Column dataField="role" caption={props.t("User Role")} cssClass="vertical-middle"
                                        dataType="string"></Column>
                                <Column dataField="device_id" caption={props.t("User Device ID")}
                                        cssClass="vertical-middle"
                                        dataType="string"></Column>
                                <Column dataField="status" caption={props.t("User Status")} cssClass="vertical-middle"
                                        dataType="string" visible={false} cellRender={object => {
                                    const value = object.data.status
                                    return (
                                        <span className="badge badge-pill font-size-12" style={{
                                            backgroundColor: badgeColorStatus(value).backgroundColor,
                                            color: badgeColorStatus(value).color
                                        }}>
                                                {value === true ? props.t("Active") : props.t("Active")}
                                            </span>
                                    )
                                }}>
                                </Column>"
                                <Column dataField="created_at" caption={props.t("Created At")}
                                        cssClass="vertical-middle" dataType="date"></Column>
                                <Column dataField="updated_at" caption={props.t("Updated At")}
                                        cssClass="vertical-middle" dataType="date"></Column>
                            </DataGrid>
                        </div>
                    </Col>
                </Row>

            </Container>

            {/*ADD MODAL*/}
            <AddUser show={isAdd} toggle={() => setIsAdd(false)} isAdding={(value) => Submit(value)}
                     userRoleData={userRoleData}/>
            {selectedUser && (
                <div>
                    <EditUser show={isEdit} toggle={() => setIsEdit(false)} isEditing={() => Submit()}
                              selectedUser={selectedUser} userRoleData={userRoleData}/>
                    <DeleteModal show={isDelete} toggle={() => setIsDelete(false)} onDelete={(data) => {
                        delUserListService(data.id).then((res) => {
                            setIsDelete(false)
                            if (!res.status) return error(res.message)
                            notify()
                            Submit()
                        })
                    }} selectedData={selectedUser} body={props.t("Do you want to delete this User?")}/>
                </div>
            )}
        </div>
    </React.Fragment>)
}

userList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(userList)
)