import React, {useEffect, useState} from "react"
import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Col, Container, Row} from "reactstrap";
import DataGrid, {
    Button,
    Column,
    ColumnChooser,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    Toolbar,
} from "devextreme-react/data-grid"
import {delProjectService, getProjectListService} from "../../services/panel/projectService";
import {badgeColorStatusValue} from "../../constants/badgecolor";
import DeleteModal from "../../components/delModal";
import {error, notify} from "../../components/toastify";
import DataGridLoadPanel from "devextreme-react/load-panel";

const projectList= (props) => {
    const position = {of: '#projectList', at: 'center', my: 'center'};
    const navigate = useHistory();
    const [projectListData, setProjectListData] = useState(null)
    const [selectedProject, setSelectedProject] = useState(null)
    const [showDelModal, setShowDelModal] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getProjectListService().then((response)=>{
            if(response){
                setProjectListData(response.data)
                setLoading(false)
            }
        })
    }, [])

    const Details = (row) => {
        navigate.push({
            pathname: "/projects/user",
            search: `?projectID=${row.id}&projectCode=${row.code}&desc=${row.description}`,
        })
    }
    const cast = (row) => {
        navigate.push({
            pathname: "/projects/cast",
            search: `?projectID=${row.id}`,
        })
    }

    const Submit = () =>  {
        getProjectListService().then((response)=>{
            if(response){
                setProjectListData(response.data)
                setLoading(false)
            }
        })
    }
    return (<React.Fragment>
        <div className="page-content">
            <Container fluid className="dash">
                <DataGridLoadPanel
                    visible={loading}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    shading={false}
                    height={100}
                    width={140}
                    message="Loading..."
                    position={position}
                />
                <h4>{props.t("Project List")}</h4>
                <Row className="mt-2">
                    <Col xl={12} className="mt-2">
                        <div className="shadow bg-white p-3 rounded-4 mt-2">
                            <DataGrid
                                allowColumnReordering={true}
                                showBorders={false}
                                className="mt-4"
                                dataSource={projectListData}
                                columnAutoWidth={true}
                                showColumnLines={false}
                                id="projectList"
                                showRowLines={true}
                            >
                                <StateStoring enabled={true} type="localStorage" storageKey="projectList" />
                                <LoadPanel enabled={false}/>
                                <Paging defaultPageSize={10}/>
                                <Pager showInfo={false} showNavigationButtons={true} showPageSizeSelector={true} allowedPageSizes={[5,10,20]}/>
                                <Grouping contextMenuEnabled={true}/>
                                <GroupPanel visible={true} emptyPanelText={props.t("Drag Here to Group")}/>
                                <ColumnChooser enabled={true} allowSearch={true} mode="select"/>
                                <SearchPanel visible={true} placeholder={props.t("Search")}/>
                                <HeaderFilter visible={true}/>
                                <Toolbar>
                                    <Item location="before"
                                          name="groupPanel"
                                    />
                                    <Item location="after"
                                          name="columnChooserButton"
                                    />
                                    <Item location="after">
                                        <button className="btn btn-outline-success" onClick={()=>{
                                            navigate.push({
                                                pathname: "/project/add",
                                            })
                                        }} ><i className="fa fa-plus"/></button>
                                    </Item>
                                </Toolbar>
                                <Column dataField="Details" caption={props.t("Transaction")} width={170} type="buttons">
                                    <Button className="btn btn-sm btn-danger bg-white border-0"
                                            onClick={(object) => {
                                                setSelectedProject(object.row.data);
                                                setShowDelModal(true);
                                            }}>
                                        <i className="fa fa-trash trash-icon"/></Button>
                                    <Button
                                        cssClass="btn btn-orange btn-sm font-size-12 ms-4"
                                        text={props.t("Cast")}
                                        onClick={(object) => {cast(object.row.data)}}>
                                    </Button>
                                    <Button
                                        cssClass="btn btn-green btn-sm font-size-12"
                                        text={props.t("Details")}
                                        onClick={(object) => {
                                            Details(object.row.data)
                                        }}>
                                    </Button>
                                </Column>
                                <Column dataField="id" caption={props.t("ID")} dataType="string" cssClass="bold text-center vertical-middle" visible={false}></Column>
                                <Column dataField="code" caption={props.t("Project Code")} dataType="string" cssClass="bold text-center vertical-middle"></Column>
                                <Column dataField="description" caption={props.t("Description")} cssClass="vertical-middle"></Column>
                                <Column dataField="status" caption={props.t("Project Status")} cssClass="text-center vertical-middle"
                                        dataType="string" visible={false} cellRender={object => {
                                    const value = object.data.status
                                    return (
                                        <span className="badge badge-pill font-size-12" style={{ backgroundColor: badgeColorStatusValue(value).backgroundColor, color: badgeColorStatusValue(value).color}}>
                                                {value !== 0 ? props.t("Active") : props.t("Passive")}
                                            </span>
                                    )}}>
                                </Column>
                                <Column dataField="completed_by" caption={props.t("Completed By")} cssClass="vertical-middle"></Column>
                                <Column dataField="created_at" caption={props.t("Created At")} dataType="date" cssClass="vertical-middle"></Column>
                                <Column dataField="updated_at" caption={props.t("Updated At")} dataType="date" cssClass="vertical-middle"></Column>
                            </DataGrid>
                        </div>
                    </Col>
                </Row>
                {selectedProject && (
                    <div>
                        <DeleteModal show={showDelModal} toggle={() => setShowDelModal(false)} onDelete={(data) => {
                            delProjectService(data.id).then((res) => {
                                setShowDelModal(false)
                                if (!res.status) return error(res.message)
                                notify()
                                Submit()
                            })
                        }} selectedData={selectedProject} body={props.t("Do you want to delete this Project?")}/>
                    </div>
                )}
            </Container>
        </div>
    </React.Fragment>)
}

projectList.propTypes = {
    location: PropTypes.any,
    t: PropTypes.any,
}

export default withRouter(
    withTranslation()(projectList)
)